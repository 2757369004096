/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react"
import { API } from "aws-amplify"
import PropTypes from "prop-types"

import Spinner from "../spin.svg"

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      settings: {},
      capacityLimit: null,
      futureBookingLimit: null,
      errors: {}
    }
  }

  async componentDidMount() {
    // Load settings
    this.loadSettings()
  }

  // Fetch settings
  getSettings(name) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", `/settings?name=${name}`, {
      headers: { Authorization: jwtToken }
    })
  }

  loadSettings = async () => {
    this.setState({ isLoading: true })
    try {
      const [capacityLimit, futureBookingLimit] = await Promise.all([
        this.getSettings("capacityLimit"),
        this.getSettings("futureBookingLimit")
      ])
      this.setState({
        settings: {
          capacityLimit: capacityLimit.settingValue.value,
          futureBookingLimit: futureBookingLimit.settingValue.value
        }
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    this.setState({ isLoading: false })
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSaveSettings = async e => {
    const { settings } = this.state
    e.preventDefault()
    this.setState({ isLoading: true })

    const { capacityLimit, futureBookingLimit } = this.state
    const {
      capacityLimit: capacityLimitOriginal,
      futureBookingLimit: futureBookingLimitOriginal
    } = settings
    // Validations:

    // check if capacity Limit is valid
    if (capacityLimit && Number.isNaN(capacityLimit)) {
      this.setState({
        isLoading: false,
        errors: { invalidCapacityLimit: "Enter a valid Capacity!" }
      })
      return
    }

    // check if future Booking Limit is valid
    if (futureBookingLimit && Number.isNaN(futureBookingLimit)) {
      this.setState({
        isLoading: false,
        errors: { invalidFutureBookingLimit: "Enter a valid Limit!" }
      })
      return
    }

    // Save settings

    try {
      // update settings
      await Promise.all([
        this.updateSettings({
          name: "capacityLimit",
          params: {
            settingValue: { value: capacityLimit || capacityLimitOriginal }
          }
        }),
        this.updateSettings({
          name: "futureBookingLimit",
          params: {
            settingValue: {
              value: futureBookingLimit || futureBookingLimitOriginal
            }
          }
        })
      ])

      // refresh & load settings
      this.loadSettings()

      // clear inputs
      this.setState({
        capacityLimit: "",
        futureBookingLimit: "",
        errors: {}
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }

    //
    this.setState({ isLoading: false })
  }

  // Update settings
  updateSettings(params) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.put("admin", `/settings`, {
      body: params,
      headers: { Authorization: jwtToken }
    })
  }

  render() {
    const {
      errors,
      isLoading,
      capacityLimit,
      futureBookingLimit,
      settings
    } = this.state
    // const { capacityLimit, futureBookingLimit } = settings

    return (
      <div className="container mx-auto bg-grey-lighter shadow-lg rounded-lg mt-6 p-6 font-sans">
        {isLoading && (
          <div className="mt-4 flex justify-center">
            <img src={Spinner} alt="spinner" />
          </div>
        )}

        <div className="mt-8">
          <span className="text-grey-darker uppercase text-xl font-bold ml-24">
            Settings
          </span>
        </div>
        {/* form start */}
        <form onSubmit={this.handleSaveSettings}>
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ml-24 mr-24 mt-4 flex flex-col my-2 items-center">
            <div className="-mx-3 flex flex-col md:flex mb-6">
              <div className="px-3 mb-6 flex flex-row items-center">
                <label className="flex-1 block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Capacity on Hill
                </label>
                <div className="flex flex-col">
                  <input
                    className="flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 focus:border-teal focus:outline-none"
                    id="capacityLimit"
                    type="text"
                    value={capacityLimit}
                    placeholder={settings.capacityLimit}
                    onChange={this.handleChange}
                  />
                  <p className="text-red text-xs italic">
                    {errors.invalidCapacityLimit}
                  </p>
                </div>
              </div>
              <div className="px-3 flex flex-row items-center">
                <label
                  className="flex-1 block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2"
                  htmlFor="futureBookingLimit"
                >
                  Booking Future limit
                  <p>(in days)</p>
                </label>
                <div className="flex flex-col">
                  <input
                    className="flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 focus:border-teal focus:outline-none"
                    id="futureBookingLimit"
                    type="text"
                    value={futureBookingLimit}
                    placeholder={settings.futureBookingLimit}
                    onChange={this.handleChange}
                  />
                  <p className="text-red text-xs italic">
                    {errors.invalidFutureBookingLimit}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="buttons_area" className="flex justify-end mr-24 mt-8 mb-8">
            {/* <div>
              <button className="bg-white text-grey-darkest font-bold rounded border-b-2 border-teal hover:border-teal-dark hover:bg-teal hover:text-white shadow-md py-2 px-6 inline-flex items-center focus:outline-none">
                <span className="mr-2">
                  {isLoading ? 'Saving...': 'Save'}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentcolor" d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                
              </button>
            </div> */}

            <button
              type="submit"
              className="bg-transparent hover:bg-teal text-teal-dark font-semibold hover:text-white py-2 px-4 border border-teal hover:border-transparent rounded-full mr-2 focus:outline-none w-1/6"
            >
              <>{isLoading === true ? "Saving..." : "Save"}</>
            </button>
          </div>
        </form>
        {/* form end */}
      </div>
    )
  }
}

Settings.propTypes = {
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default Settings
