import React, { Component } from "react"
import { API, Auth } from "aws-amplify"
import PropTypes from "prop-types"
import ReservationsFilters from "../components/ReservationsFilters"
import ReservationTableRow from "../components/ReservationTableRow"
import Modal from "../components/modal"
import ReservationAddForm from "../components/ReservationAddForm"

import Spinner from "../spin.svg"

class Reservations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      reservations: [],
      startTime: null,
      endTime: null,
      firstLoad: true,
      editMode: false,
      editId: null,
      showAddReservationModal: false,
      addReservationErrors: {},
      userAttributes: {}
    }
  }

    

  async componentDidMount() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return
    }

    const user = await Auth.currentAuthenticatedUser()
    // this.setState({ user })

    // check if admin 
    const userAttributes = await this.getAdminStatusRequest(user)
    
    this.setState({
      userAttributes
    })

    // Set today
    this.setToday()

    // load reservations
    this.loadReservations(0)
  }

   // Get user admin status
   getAdminStatusRequest = (user) => {
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users/${user.username}`, { headers: { Authorization: jwtToken } })
  }

  // Fetch all reservations
  getReservations(flag, startTime, endTime) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", `/reservations?filters=${flag}${flag === 1 ? `&date-start=${startTime}&date-end=${endTime}` : ''}`, {
      headers: { Authorization: jwtToken }
    })
  }

  openAddReservationModal = (editMode, id, e) => {
    e.preventDefault()

    this.setState({
      showAddReservationModal: true,
      editMode,
      editId: id
    })
  }

  closeModal = () => {
    this.setState({
      showAddReservationModal: false
    })
  }

  // Add new reservation
  handleSubmitNewReservation = async (data, editMode, editId, e) => {
    e.preventDefault()
    this.setState({ isLoading: true })

    try {
      if (editMode) {
        await this.updateReservation(editId, data)
      } else {
        await this.addNewReservation(data)
      }

      // close Modal
      this.closeModal()

      // refresh reservations
      this.loadReservations(0)
    } catch (err) {
      this.setState({ addReservationErrors: err.response.data.errors })
    }

    //
    this.setState({ isLoading: false })
  }

  // handle delete reservation
  // eslint-disable-next-line no-unused-vars
  handleDeleteReservation = async (id, e) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      "Are you sure you want to delete this reservation?"
    )

    if (!confirmed) {
      return
    }

    this.setState({ isLoading: true })

    try {
      await this.deleteReservation({ id })

      // refresh reservations list
      this.loadReservations(0)
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err)
    }

    //
    this.setState({ isLoading: false })
  }

  handleChangeStart = (startTime) => {
    const { endTime } = this.state
    this.setState({startTime, firstLoad: false})
    this.loadReservations(1,startTime, endTime)
  }

  handleChangeEnd = (endTime) => {
    const { startTime } = this.state
    this.setState({ endTime, firstLoad: false })
    this.loadReservations(1,startTime, endTime)
  }

  // update reservation API request
  updateReservation(uuid, data) {
    const { user } = this.props
    // console.log(`/reservations/${uuid}`)
    const { jwtToken } = user.signInUserSession.idToken
    return API.put("admin", `/reservations/${uuid}`, {
      body: data,
      headers: { Authorization: jwtToken }
    })
  }

  // delete reservation API request
  deleteReservation(data) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.del("admin", "/reservations", {
      body: data,
      headers: { Authorization: jwtToken }
    })
  }


    // Add new reservation API request
    addNewReservation(data) {
      const { user } = this.props
      const { jwtToken } = user.signInUserSession.idToken
      return API.post("admin", "/reservations", {
        body: data,
        headers: { Authorization: jwtToken }
      })
    }

  async loadReservations(flag, startTime, endTime) {
    this.setState({ isLoading: true })

    //
    try {
      const reservations = await this.getReservations(flag, startTime, endTime)
      this.setState({ reservations })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    //
    this.setState({ isLoading: false })
  }

  setToday = () => {
    const startTime = new Date()
    startTime.setHours(0, 0, 0, 0);
    const endTime = new Date(startTime)
    endTime.setHours(23, 59, 59, 999);

    this.setState({ startTime, endTime, firstLoad: true })
    this.loadReservations(0)
    }

  render() {
    const {
      reservations,
      isLoading,
      showAddReservationModal,
      editMode,
      editId,
      addReservationErrors,
      userAttributes
    } = this.state

    const { user } = this.props
    const { startTime, endTime, firstLoad } = this.state
    const filterProps = { 
            startTime, 
            endTime,
            handleChangeStart: this.handleChangeStart,
            handleChangeEnd: this.handleChangeEnd,
            setToday: this.setToday,
            firstLoad
           }

    return (
      <div className="container mx-auto">
        {/* Add new reservation modal */}
        {showAddReservationModal && (
          <Modal closeAction={this.closeModal}>
            <ReservationAddForm
              handleSubmitNewReservation={this.handleSubmitNewReservation}
              closeModal={this.closeModal}
              user={user}
              errors={addReservationErrors}
              // clearAddReservationErrors={this.clearAddReservationErrors}
              isLoading={isLoading}
              editMode={editMode}
              editId={editId}
            />
          </Modal>
        )}

        {/* reservation Filters component */}
        <ReservationsFilters
          handleChange={this.handleChange}
          openAddReservationModal={this.openAddReservationModal}
          userAttributes={userAttributes}
          filterProps={filterProps}
        />

        {isLoading ? (
          // Spinner component
          <div className="mt-4 flex justify-center">
            <img alt="" src={Spinner} className="w-10" />
          </div>
        ) : (
          // reservations Table
          <div className="bg-white shadow">
            <div className="mt-4 flex items-center">
              <table className="w-full">
                <tbody>
                  {/* reservations table header */}
                  <tr className="bg-grey">
                    <td className="p-2 pl-2 font-bold">Date</td>
                    <td className="p-2 pl-2 font-bold">Time</td>
                    <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                      Amount
                    </td>

                    <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                      Description
                    </td>

                    <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                      { !userAttributes.isRental && !userAttributes.isSupporter && 'Action'}
                    </td>
                  </tr>

                  {/* reservations table body */}
                  {reservations
                    ? reservations.map(reservation => (
                        // eslint-disable-next-line react/jsx-indent
                        <ReservationTableRow
                          key={reservation.id}
                          reservation={reservation}
                          openAddReservationModal={this.openAddReservationModal}
                          handleDeleteReservation={this.handleDeleteReservation}
                          userAttributes={userAttributes}
                        />
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    )
  }
}

Reservations.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default Reservations