import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Modal extends PureComponent {

  constructor(props) {
    super(props)
    this.closeAction = props.closeAction.bind(this)
  }

  render() {

    const { children, size } = this.props
    const sizeCss = size === 'lg'
      ? 'lg:w-5/6'
      : 'max-w-md w-full'

    return (
      <div
        style={{ backgroundColor: 'rgba(232, 255, 254, 0.75)'}}
        className="fixed flex pin overflow-auto z-50 bg-teal-lightest"
      >
        <div className={`${sizeCss} animated fadeInUp fixed shadow-inner md:relative pin-t h-full pin-x align-top m-auto md:justify-center p-8 bg-white md:rounded md:h-auto md:shadow-lg flex flex-col`}>
          {children}
          <button onClick={this.closeAction} type="button" className="absolute pin-t pin-r pt-4 px-4">
            <svg className="fill-current h-12 w-12 text-grey-darker hover:text-grey-dark" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>
                Close
              </title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  closeAction: PropTypes.func.isRequired,
  size: PropTypes.string
}

Modal.defaultProps = {
  size: ''
}

export default Modal