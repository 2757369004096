import React from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'

registerLocale('en-GB', enGB)

class VoucherAddForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      voucherDescription: "",
      voucherQuantity: 1,
      voucherAmount: '',
      voucherUserEmail: '',
      voucherTypeRadio: null,
      maximumRedemptions: '',
      errors: {},
      // isLoading: false,
      productsVisible: false
    }

    this.toggleProductsBlock = this.toggleProductsBlock.bind(this)
    this.handleChangeVoucherRadio = this.handleChangeVoucherRadio.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { props } = this
    props.clearAddVoucherErrors()
  }
  
  componentWillReceiveProps(props) {
    this.setState({ errors: props.errors, isLoading: props.isLoading  })
  }
  
  handleChangeVoucherRadio(e) {
    this.setState({
      voucherTypeRadio: e.target.value
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleDateSelect(date, attribute) {
    const dateTime = date && new Date(date)
    if (attribute === 'validTo') {
      dateTime.setHours(23, 59, 59, 999) // End of day
    }
    this.setState({ [attribute]: dateTime })
  }

  // Used to populate SKUs list categorized by thier product name
  populateProductsList() {
    const { props, state } = this
    const { skus } = props

    return skus.map((product) => (
      <div key={product.productId}>
        <h4 className="font-sans text-grey-darker font-bold mt-4">
          {product.productName}
        </h4>

        {product.skus.map((sku, i) => {
          const quantity = parseInt(state[`${product.productId}_quantity-${i}`], 10) || 1
          const id = `${product.productId}_skuIndex-${i}` 
          const { checked } = document.getElementById(id) || false

          return (
            <div key={sku.id} className="my-1">
              <label htmlFor={id} className="text-grey-darker">
                <input
                  type="checkbox"
                  name={product.productId}
                  id={id}
                  value={sku.id}
                  onChange={(e) => props.handleProductSelect(id, product.productId, e.target.checked, quantity)}
                />
                &nbsp;&nbsp;
                <input
                  type="number"
                  name={product.productId}
                  id={`${product.productId}_quantity-${i}`}
                  value={quantity}
                  onChange={(e) => { this.handleChange(e); props.handleProductSelect(id, product.productId, checked, e.target.value) }}
                  className="w-8 border"
                />              
                &nbsp;&nbsp;
                <span className={checked ? 'text-grey-darker' : 'text-grey'}>
                  {Object.keys(sku.attributes).map(key => (
                    <span key={key} className="rounded text-xs mr-2">
                      <span className="">{key}</span>
                      &nbsp;
                      <span className="font-bold text-grey-darker">{sku.attributes[key]}</span>
                    </span>
                  ))}
                </span>
              </label>
            </div>
          )
        })}
      </div>
    ))
  }

  // Show/hide products dropdown block
  toggleProductsBlock() {
    const { state } = this
    const productsVisible = !state.productsVisible
    this.setState({ productsVisible })
  }

  
  render() {
    const { props } = this
    const { isLoading, 
            voucherAmount, 
            voucherUserEmail, 
            voucherTypeRadio, 
            productsVisible, 
            voucherQuantity, 
            errors, 
            maximumRedemptions, 
            validFrom, 
            validTo,
            voucherDescription
          } = this.state
    const data = {
      description: voucherDescription,
      qty: voucherQuantity,
      amount: voucherAmount,
      userEmail: voucherUserEmail,
      voucherType: voucherTypeRadio,
      voucherValidFrom: validFrom,
      voucherValidTo: validTo,
      voucherMaximumRedemptions: maximumRedemptions
    }
    
    return (
      <>
        <form className="w-full font-sans" onSubmit={(e) => props.handleSubmitNewVoucher(data, e)}>
          <div className="text-sm text-grey mt-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voucherDescription">
              <span className="mr-10">Description:&nbsp;</span>
              <input value={voucherDescription} onChange={this.handleChange} className="mt-2 shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" name="voucherDescription" id="voucherDescription" type="text" placeholder="Enter Voucher description" />
            </label>
            {/* <p className="text-red text-xs italic mt-2">{errors.invalidVoucherQuantity}</p> */}
          </div>
          <div className="text-sm text-grey mt-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voucherQuantity">
              Voucher quantity:&nbsp;
              <input value={voucherQuantity} onChange={this.handleChange} className="mt-2 shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" name="voucherQuantity" id="voucherQuantity" type="text" placeholder="Enter Voucher quantity" />
            </label>
            <p className="text-red text-xs italic mt-2">{errors.invalidVoucherQuantity}</p>
          </div>
          <div className="text-sm text-grey mt-4">
            <div className="block text-grey-darker text-sm font-bold mb-2">
              Voucher amount in:
            </div>
          
            <div className="flex items-center mr-4 mb-2 font-bold">
              <label htmlFor="voucherTypeDKK" className="flex items-center cursor-pointer text-grey-darker ml-2">
                <input type="radio" name="voucherTypeRadio" id="voucherTypeDKK" value="1" onChange={this.handleChangeVoucherRadio} />
                &nbsp;DKK
              </label>
            </div>
            <div className="flex items-center mr-4 font-bold">
              <label htmlFor="voucherTypePercentage" className="flex items-center cursor-pointer text-grey-darker ml-2">
                <input type="radio" name="voucherTypeRadio" id="voucherTypePercentage" value="2" onChange={this.handleChangeVoucherRadio} />
                &nbsp;Discount percentage
              </label>
            </div>
            <p className="text-red text-xs italic">
              {errors.invalidVoucherTypeSelection}
            </p>
          
            <input onChange={this.handleChange} className="mt-2 shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" name="voucherAmount" id="voucherAmount" type="text" placeholder="Enter Voucher amount" />
            <p className="text-red text-xs italic mt-2">
              {errors.invalidAmount}
            </p>
          </div>

          <div className="text-sm text-grey mt-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="username">
            Optional: 
              {' '}
              <span className="text-grey-dark font-light text-xs italic">assign to specific user email</span>
            </label>
            <input onChange={this.handleChange} className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" name="voucherUserEmail" id="voucherUserEmail" type="text" placeholder="i.e. tom@jones.com" />
            <p className="text-red text-xs italic mt-2">
              {errors.invalidEmail}
            </p>
            <p className="block text-grey-darker text-sm font-bold mt-2">
              NOTE: 
              <span className="text-grey-dark font-light text-xs italic"> the user has to be already exist</span>
            </p>
            <p className="text-red text-xs italic mt-2">
              {errors.email}
            </p>
          </div>

          {/* products dropdown */}
          <div className="text-sm text-grey mt-6 w-3/5">

            <button type="button" className="flex flex-row justify-between w-full border-b pb-2 cursor-pointer focus:outline-none" onClick={this.toggleProductsBlock}>
              <div className="text-grey-darker font-bold">
                Specific Products
              </div>
              <div className="flex items-center">
                <svg
                  style={{ transform: productsVisible ? 'rotate(180deg)' : '' }}
                  className="fill-current text-black h-5 w-5" 
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
                {/* <img alt="arrow" className="fill-current w-4 h-4" src={productsVisible ? "https://img.icons8.com/ios/50/000000/up-squared.png" : "https://img.icons8.com/ios/50/000000/down-squared--v1.png"} /> */}
              </div>
            </button>

            {productsVisible && (
              <div className="pl-4 border-b">
                <div
                  className="h-full mb-1 ml-2" // overflow-scroll
                >
                  {this.populateProductsList()}            
                </div>
              </div>
            )}
            
            <div className="text-grey-darker text-sm font-bold mt-6">
              <label htmlFor="maximumRedemptions" className="flex items-center">
                <div>
                  Maximum amount of redemptions
                </div>
                <input 
                  name="maximumRedemptions"
                  id="maximumRedemptions"
                  type="number"
                  value={maximumRedemptions}
                  placeholder="1"
                  onChange={this.handleChange}
                  style={{width: '5rem'}}
                  className="ml-4 shadow appearance-none border rounded py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal"
                />
              </label>
            </div>
            <p className="text-red text-xs italic mt-2">
              {errors.invalidMaximumRedemptions}
            </p>
          </div>

          <div>
            <div className="flex items-center text-grey-darker text-sm font-bold mt-6">
  
              <div className="mr-4">
                Restrict to date interval
              </div>
              <DatePicker
                selected={validFrom}
                selectsStart
                startDate={validFrom}
                endDate={validTo}
                dateFormat="d MMM yyyy"
                // onChange={this.handleChange}
                onChange={date => this.handleDateSelect(date, 'validFrom')}
                locale="en-GB"
                className="mr-4 text-sm appearance-none border-2 rounded w-32 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
              />
              <DatePicker
                selected={validTo}
                selectsEnd
                startDate={validFrom}
                endDate={validTo}
                minDate={validFrom}
                dateFormat="d MMM yyyy"
                // onChange={this.handleChange}
                onChange={date => this.handleDateSelect(date, 'validTo')}
                locale="en-GB"
                className="text-sm appearance-none border-2 rounded w-32 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
              />
            </div>
            <p className="text-red text-xs italic mt-2">
              {errors.invalidDateInterval}
            </p>
          </div>

          <div className="md:flex md:items-center mb-6 mt-6">

            <button type="submit" className="bg-transparent hover:bg-teal text-teal-dark font-semibold hover:text-white py-2 px-4 border border-teal hover:border-transparent rounded-full mr-2 focus:outline-none w-1/4">
              <>
                {isLoading === true
                  ? "Loading..."
                  : "Save Voucher"}
              </>
            </button>
            <button type="button" onClick={props.closeModal} className="bg-transparent hover:bg-red text-red-dark font-semibold hover:text-white py-2 px-4 border border-red hover:border-transparent rounded-full mr-2 focus:outline-none">
              Cancel
            </button>
            
          </div>

        </form>
      </>
    )
  }

}

VoucherAddForm.propTypes = {
  clearAddVoucherErrors: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleProductSelect: PropTypes.func.isRequired,
  handleSubmitNewVoucher: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

VoucherAddForm.defaultProps = {
  errors: {},
  isLoading: false
}

export default VoucherAddForm