/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

class bookingEditForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      booking: props.booking,
      communicating: false
    }
    this.changeStartDate = this.changeStartDate.bind(this)
    this.changeEndDate = this.changeEndDate.bind(this)
    this.cancelBooking = this.cancelBooking.bind(this)
    this.undoCancelBooking = this.undoCancelBooking.bind(this)
  }

  changeStartDate(date) {
    const { booking } = this.state
    this.setState({
      booking: {
        ...booking,
        dateStart: date
      }
    })
  }

  changeEndDate(date) {
    const { booking } = this.state
    this.setState({
      booking: {
        ...booking,
        dateEnd: date
      }
    })
  }

  cancelBooking() {
    const { submit } = this.props
    this.setState({ communicating: true })
    const { booking } = this.state
    booking.cancelledAt = new Date()
    submit(booking)
  }

  undoCancelBooking() {
    const { submit } = this.props
    this.setState({ communicating: true })
    const { booking } = this.state
    booking.cancelledAt = null
    submit(booking)
  }

  render() {
    const { booking, communicating } = this.state
    const { submit } = this.props

    return (
      <>
        <h3>
          {booking.user.firstName}
          {booking.user.lastName}
        </h3>
        <div>
          <a
            href={`mailTo:${booking.user.email}`}
            className="text-teal no-underline"
          >
            {booking.user.email}
          </a>
        </div>
        <div className="text-sm text-grey mt-4">
          Created at:&nbsp;
          <strong>
            {/* {new Date(booking.createdAt).toLocaleDateString("da-DK", { day: '2-digit', month: '2-digit', year: 'numeric' })}
                &nbsp; */}
            {new Date(booking.createdAt).toLocaleString("da-DK", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric"
            })}
          </strong>
        </div>
        <div className="h-px my-4 bg-teal-lighter" />
        <form className="w-full">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="timeStart"
              >
                Time start
              </label>
            </div>
            <div className="md:w-2/3">
              <DatePicker
                selected={new Date(booking.dateStart)}
                onChange={this.changeStartDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                // minTime={setHours(setMinutes(new Date(), 0), 17)}
                // maxTime={setHours(setMinutes(new Date(), 30), 20)}
                dateFormat="d MMMM yyyy, HH:mm"
                className="appearance-none border-2 rounded w-64 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
              />
              {/* <input className="appearance-none border-2 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal" id="timeStart" type="text" value="Jane Doe" /> */}
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="timeEnd"
              >
                Time end
              </label>
            </div>
            <div className="md:w-2/3">
              <DatePicker
                selected={new Date(booking.dateEnd)}
                onChange={this.changeEndDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                // minTime={setHours(setMinutes(new Date(), 0), 17)}
                // maxTime={setHours(setMinutes(new Date(), 30), 20)}
                dateFormat="d MMMM yyyy, HH:mm"
                className="appearance-none border-2 rounded w-64 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
              />
            </div>
          </div>
          {communicating ? (
            <div>Communicating...</div>
          ) : (
            <>
              <div className="md:flex md:items-center w-full">
                <div className="md:w-1/3">
                  {!booking.cancelledAt && (
                    <button
                      onClick={this.cancelBooking}
                      className="text-red-dark text-sm"
                      type="button"
                    >
                      Cancel booking
                    </button>
                  )}
                </div>
                <div className="md:flex-grow">
                  <button
                    onClick={() => submit(booking)}
                    className="shadow bg-teal hover:bg-teal-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="button"
                  >
                    Save
                  </button>
                </div>
              </div>
              {booking.cancelledAt && (
                <div className="text-sm text-grey mt-4">
                  Cancelled at:&nbsp;
                  <strong>
                    {new Date(booking.cancelledAt).toLocaleString("da-DK", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric"
                    })}
                  </strong>
                  <br />
                  by
                  {booking.updatedBy}
                  &nbsp;
                  <button
                    onClick={this.undoCancelBooking}
                    className="font-bold text-grey underline"
                    type="button"
                  >
                    Undo cancellation
                  </button>
                </div>
              )}
            </>
          )}
        </form>
      </>
    )
  }
}

bookingEditForm.propTypes = {
  booking: PropTypes.objectOf(PropTypes.object).isRequired,
  submit: PropTypes.func.isRequired
}

export default bookingEditForm
