/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, NavLink } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'

import packageJson from '../../package.json'

class TopMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticating: true,
      isExpanded: false
    }
   }

  async componentDidMount() {
    
    try {
      await Auth.currentSession();
                        
      const user = await Auth.currentAuthenticatedUser()
      // this.setState({ user })

      // check if admin 
      const userAttributes = await this.getAdminStatusRequest(user)
      
      // Set 'isRentalUser' & 'inInstruction' status
      this.setState({ isRentalUser: userAttributes.isRental, 
                      isInstructionUser: userAttributes.isInstruction, 
                      isSupporter: userAttributes.isSupporter,
                      userEmail: userAttributes.email })
      
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    
    this.setState({ isAuthenticating: false})
  }

  toggleExpansion = (val) => {
    this.setState({ isExpanded: val })
  }

  // Get user admin status
  getAdminStatusRequest = (user) => {
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users/${user.username}`, { headers: { Authorization: jwtToken } })
  }

  render() {
    const { isAuthenticating, isRentalUser, isInstructionUser, isSupporter, userEmail, isExpanded } = this.state
    const { handleLogout } = this.props
    const adminType = (isRentalUser === false && isInstructionUser === false && isSupporter === false) ? 'Admin' : 
    isRentalUser ? 'Rental Admin' : isInstructionUser ? 'Instruction Admin' : 'Supporter'


    return (
      <header className="sticky pin-t">
      <ul className="list-reset flex items-center border-b border-teal-dark bg-white shadow-md">
        <li className="mr-6 py-3 px-4 bg-teal">
          <div className="text-teal-darker text-lg uppercase font-bold">
            <div className="text-xs font-bold text-teal-darkest">{`RE:BOOK (${packageJson.version})`}</div>
            <div className="leading-none">CopenHill</div>
          </div>
        </li>
        {/* Rental user menu */}
        {!isAuthenticating && isRentalUser && (
          <>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/rentals"
              >
                Rentals
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/reservations"
              >
                Reservations
              </NavLink>
            </li>
          </>
        )}
        {/* Instruction user menu */}
        {!isAuthenticating && isInstructionUser && (
          <li className="mr-2 text-sm">
            <NavLink
              className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
              activeClassName="border-teal text-teal-darker border-b-4"
              to="/instructions"
            >
              Instructions
            </NavLink>
          </li>
        )}
        {/* Supporter user menu */}
        {!isAuthenticating && isSupporter && (
          <>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/bookings"
              >
                Bookings
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/reservations"
              >
              Reservations
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/openhours"
              >
                Open hours
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/keycards"
              >
                Key cards
              </NavLink>
            </li>
          </>
        )}

        {/* Non-rental/instruction menu start */}
        {!isAuthenticating && !isRentalUser && !isInstructionUser && !isSupporter && (
          <>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/bookings"
              >
                Bookings
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/reservations"
              >
                Reservations
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/customers"
              >
                Customers
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/vouchers"
              >
                Vouchers
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/admins"
              >
                Admins
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/openhours"
              >
                Open hours
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/settings"
              >
                Settings
              </NavLink>
            </li>
            <li className="mr-2 text-sm">
              <NavLink
                className="block p-4 no-underline text-grey-dark hover:text-teal-darker"
                activeClassName="border-teal text-teal-darker border-b-4"
                to="/keycards"
              >
                Key cards
              </NavLink>
            </li>
          </>
        )}
        {/* Non-rental/instruction menu end */}

        <li className="flex-grow">{/* spacer */}</li>
        {/* <li className="mr-2">{`V ${packageJson.version}`}</li> */}
        <li className="mr-6 text-sm">{`${userEmail} (${adminType})`}</li>
        <li className="mr-6 text-sm">
          <button
            onClick={handleLogout}
            className="p-4 no-underline text-teal hover:text-teal-darker"
            type="button"
          >
            Log out
          </button>
        </li>
      </ul>
      </header>
    )
  }
}

TopMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired
}

export default withRouter(TopMenu)