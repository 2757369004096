/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react"
import { API } from 'aws-amplify'

class KeyCards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      serial: '',
      isLoading: false
    }
  }

  async componentDidMount() {
    const { serial } = this.state
      if(parseInt(serial, 10).toString().length >= 4) {
        this.getUserData()
      }
  }
  
  getUserData = async () => {
    this.setState({ isLoading: true });

    //
    try {
      const userData = await this.fetchUserData()
      this.setState({ userData })
    } catch (e) {
      // eslint-disable-next-line no-alert
      console.log(e)
    }

    //
    this.setState({ isLoading: false })
  }
  

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, () => { 
      const { serial } = this.state
      console.log(parseInt(serial, 10))
      if(parseInt(serial, 10).toString().length >= 4) {
        this.getUserData()
      }
      
     })
  }

    // Fetch user data
    fetchUserData() {
      const { user } = this.props
      const { serial } = this.state
      const { jwtToken } = user.signInUserSession.idToken
      return API.get('admin', `/bookings/orders/${parseInt(serial)}`, { headers: { Authorization: jwtToken } })
    }

  populateUserData = () => {
    const { userData } = this.state
    const { firstName, lastName, email } = userData.booking.user
    const { membership, axess } = userData
    const { createdAt } = membership
    const memberSince = new Date(createdAt)
    return (
      <div className="mt-4 items-center whitespace-pre-line">
        <p>
          <span className="block"><span className="font-bold">SN: </span>{axess.serialNo}</span>
        </p>
        <p>
          <span className="block"><span className="font-bold">Name: </span>{`${firstName} ${lastName}`}</span>
        </p>
        <p>
          <span className="block"><span className="font-bold">E-mail: </span>{`${email}`}</span>
        </p>
        <p>
          <span className="block"><span className="font-bold">Member since: </span>{`${memberSince.toLocaleDateString()}`}</span>
        </p>
        <p>
          {/* <span className="block"><span className="font-bold">Memberships: </span><ul> {memberships.map(membership => (<li>{membership.type.split(/(?=[A-Z])/).join(" ")}</li>) )} </ul></span> */}
          <span className="block"><span className="font-bold">Membership: </span>{membership.status === null ? membership.type.split(/(?=[A-Z])/).join(" ") : membership.status }</span>
        </p>
        <div className="mt-4 font-sm">
          <p>
            <span className="block font-bold">NB</span>
          </p>
          <p>
            <span className="block italic">The data retrieved is valid if and only if, a key card exists with the serial number printet upon it.</span>
          </p>
          <p>
            <span className="block font-bold mt-4">Serial number</span>
          </p>
          <p>
            <span className="block italic">The serial number consists of the last 4  digits on the card</span>
          </p>
        </div>  
      </div>
    )
  }

  populateNotFound = () => {
    const { serial } = this.state
    return (
      <div className="mt-4 items-center whitespace-pre-line">
        <p>
          <span className="block"><span className="font-bold">SN: </span>{serial}</span>
        </p>
        <p>
          <span className="block">Serial number wasn't found</span>
        </p>
      </div>
    )
  }

  render() {
    const { userData } = this.state
    return (
      <div className="container mx-auto">
        <div className="mt-4 flex items-center">
          <h1 className="mr-4 text-teal-darker leading-none">Key Cards</h1>
        </div>
        <div className="text-teal-dark mr-1 pt-4">Search by serial No.:</div>

        <form className="w-full max-w-sm">
          <div className="flex items-center border-b border-b-2 border-teal-500 py-2">
            <input
              className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              name="serial"
              id="serial"
              type="text"
              onChange={this.handleChange}
              placeholder="Card serial No."
            />
          </div>
        </form>

        {userData && userData.booking && userData.booking.user && !userData.error && this.populateUserData()}
        {userData && userData.error && this.populateNotFound()}
      </div>
    )
  }
}

export default KeyCards
