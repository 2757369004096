import React, { Component } from "react"
import { API } from "aws-amplify"
import PropTypes from "prop-types"
import AdminsFilters from "../components/AdminsFilters"
import AdminsTableRow from "../components/AdminsTableRow"

import Spinner from "../spin.svg"

class Admins extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      admins: [],
      searchTerm: "",
      users: []
    }
  }

  async componentDidMount() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return
    }

    this.updateAdminsList()
  }

  getAdmins() {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", "/users", {
      headers: { Authorization: jwtToken },
      queryStringParameters: {
        filters: JSON.stringify([{
          isAdmin: true
        }])
      }
    })
  }

  updateAdminsList = async () => {
    
    try {
      this.setState({ isLoading: true })
      const admins = await this.getAdmins()
      this.setState({ admins })
    } catch (e) {
      alert(e)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
    if (e.target.id === 'searchTerm') {
      this.fetchUsers(e.target.value)
    }
  }

  handleAdminUser = async (sub, status, type) => {
    const confirmed = window.confirm(
      status === true
        ? "Are you sure you want to grant admin privileges to this user?"
        : "Are you sure you want to remove admin privileges from this user?"
    )

    if (!confirmed) {
      return
    }

    this.setState({ isLoading: true })

    try {
      await this.updateUserAdmin({
        params: {
          isAdmin: status,
          ...(status === false && {isRental: false}),
          ...(status === false && {isInstruction: false}),
          ...(status === false && {isSupporter: false}),
          ...(status === true && type === 'isRental' && {isRental: true}),
          ...(status === true && type === 'isInstruction' && {isInstruction: true}),
          ...(status === true && type === 'isSupporter' && {isSupporter: true})
        },
        pathParameters: {
          userId: sub
        }
      })

      // refresh users list
      this.updateAdminsList()
    } catch (e) {
      alert(e)
    }

    //
    this.setState({ isLoading: false, users: [], searchTerm: '' })
  }

  // render static list of current admins
  renderUsersListAdminsStatic = () => {
    const { admins = [] } = this.state
    return admins
      .map(admin => (
        <AdminsTableRow
          key={admin.id}
          admin={admin}
          handleAdminUser={this.handleAdminUser}
        />
      ))
  }

  fetchUsers = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ isLoading: true })

    if (!searchTerm) {
      this.setState({ users: []})
      this.setState({ isLoading: false })
      return
    }
    
    this.timeout = setTimeout(async () => {
      try {
        const { user } = this.props
        const { jwtToken } = user.signInUserSession.idToken
        const users = await API.get("admin", "/users", {
          headers: { Authorization: jwtToken },
          queryStringParameters: { 
            searchTerm,
            filters: JSON.stringify([{ isAdmin: false }])
          }
        })
        this.setState({ users })
      } catch (e) {
        console.error(e)
      } finally {
        this.setState({ isLoading: false })
      }

      // If page is initially visited, load with not waiting, otherwise wait for typing to finish
    }, 750)
  }

  // update user admin status (grant/demote) admin
  updateUserAdmin(params) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.put("admin", `/users`, {
      body: params,
      headers: { Authorization: jwtToken }
    })
  }

  render() {
    const { isLoading, searchTerm, users } = this.state

    return (
      <>
        {/* Admins view block */}
        <div className="container mx-auto">
          {/* Admins view block */}
          <div className="mt-4 flex items-center">
            <h1 className="mr-4 text-teal-darker leading-none">Admins</h1>
          </div>
          <div className="bg-white shadow">
            <div className="mt-4 flex items-center">
              <table className="w-full">
                <tbody>
                  {/* Admins table header */}
                  <tr className="bg-grey">
                    <td className="p-2 pl-2 font-bold">ID</td>
                    <td className="p-2 pl-2 font-bold">Email</td>
                    <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                      Name
                    </td>
                    <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                     Role
                    </td>

                    <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                      Action
                    </td>
                  </tr>

                  {/* Admins view */}
                  {this.renderUsersListAdminsStatic()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Add admin block */}
        <div className="container mx-auto">
          <AdminsFilters handleChange={this.handleChange} searchTerm={searchTerm} />

          {isLoading ? (
            <div className="mt-4 flex justify-center">
              <img src={Spinner} alt="spinner" />
            </div>
          ) : (
            <div className="bg-white shadow">
              <div className="mt-4 flex items-center">
                <table className="w-full">
                  <tbody>
                    <>
                      {/* Admins table header */}
                      {searchTerm && (
                        <tr className="bg-grey">
                          <td className="p-2 pl-2 font-bold">ID</td>
                          <td className="p-2 pl-2 font-bold">Email</td>
                          <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                            Name
                          </td>
                          <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                            Role
                          </td>

                          <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                            Grant privileges of
                          </td>
                        </tr>
                      )}

                      {users.map(user => (
                        <AdminsTableRow
                          key={user.id}
                          admin={user}
                          handleAdminUser={this.handleAdminUser}
                        />
                      ))}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}

Admins.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default Admins
