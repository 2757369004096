/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Auth, API } from 'aws-amplify'
import DatePicker, { registerLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'
import Spinner from "../spin.svg"

registerLocale('en-GB', enGB)

const [INITIAL, PREPARING, READY, FAILED] = ['INITIAL', 'PREPARING', 'READY', 'FAILED']

const BookingFilters = props => {
  const { filterProps } = props
  const {
    filteredBookings: bookings,
    startTime,
    endTime,
    paxCount,
    filters,
    setToday,
    handleChangeStart,
    handleChangeEnd,
    toggleFilter,
    hideCanceled,
    toggleHideCancel,
    handleSearchChanged,
    isSupporter,
  } = filterProps

  const [csvStatus, setCsvStatus] = useState(INITIAL)
  const [csvBlobUrl, setCsvBlobUrl] = useState(null)

  const prepareCSV = async () => {
    try {
      setCsvStatus(PREPARING)
      
      const user = await Auth.currentAuthenticatedUser()
      const { jwtToken } = user.signInUserSession.idToken
      
      const { url } = await API.get('bookings', `/bookings/csv-export?date-start=${startTime}&date-end=${endTime}&filters=${filters}`, { headers: { Authorization: jwtToken } }) || {}
      setCsvBlobUrl(url)

      setCsvStatus(READY)
    } catch (e) {
      setCsvStatus(FAILED)
    }
  }
  
  const prepareFinancialCSV = async () => {
    try {
      setCsvStatus(PREPARING)
      
      const user = await Auth.currentAuthenticatedUser()
      const { jwtToken } = user.signInUserSession.idToken
      
      const { url } = await API.get('bookings', `/bookings/csv-export-financial?date-start=${startTime}&date-end=${endTime}&filters=${filters}`, { headers: { Authorization: jwtToken } }) || {}
      setCsvBlobUrl(url)

      setCsvStatus(READY)
    } catch (e) {
      setCsvStatus(FAILED)
    }
  }

  useEffect(() => {
    setCsvStatus(INITIAL)
  }, [startTime, endTime])

  return (
    <div className="container mx-auto">
      <div className="mt-4 flex items-center">
        <h1 className="mr-4 text-teal-darker leading-none">Bookings</h1>
        <button
          onClick={setToday}
          className="mr-4 text-teal-dark"
          type="button"
        >
          Today
        </button>
        <div className="mr-4">&bull;</div>
        <div className="text-xs mr-1">From</div>
        <div>
          <DatePicker
            selected={startTime}
            selectsStart
            startDate={startTime}
            endDate={endTime}
            dateFormat="d MMM yyyy"
            onChange={handleChangeStart}
            locale="en-GB"
            className="text-sm appearance-none border-2 rounded w-24 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
          />
        </div>
        <div className="text-xs mx-1">To</div>
        <div>
          <DatePicker
            selected={endTime}
            selectsEnd
            startDate={startTime}
            endDate={endTime}
            dateFormat="d MMM yyyy"
            onChange={handleChangeEnd}
            locale="en-GB"
            className="text-sm appearance-none border-2 rounded w-24 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
          />
        </div>

        {/* Search by e-mail block */}
        <div className="ml-2 mr-1 text-sm">Search</div>

        <form
          className="text-sm"
          style={{ width: '12rem' }}
        >
          <div className="flex items-center border-b border-b-2 border-teal-500 py-2">
            <input
              onChange={handleSearchChanged}
              className="w-full shadow appearance-none border rounded py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              name="search"
              id="search"
              type="text"
              placeholder="Customer email or name"
            />
          </div>
        </form>

        <div className="flex flex-grow items-center text-xs ml-4 leading-none">
          <strong>Filters:</strong>
          <label className="mx-2 whitespace-no-wrap">
            Rental&nbsp;&nbsp;
            <input
              name="rental"
              type="checkbox"
              checked={filters.includes("rental")}
              onChange={toggleFilter}
            />
          </label>
          <label className="mx-2 whitespace-no-wrap">
            Instruction&nbsp;&nbsp;
            <input
              name="instruction"
              type="checkbox"
              checked={filters.includes("instruction")}
              onChange={toggleFilter}
            />
          </label>
          <label className="mx-2 whitespace-no-wrap">
            Hide canceled&nbsp;&nbsp;
            <input
              name="hideCanceled"
              type="checkbox"
              checked={hideCanceled}
              onChange={toggleHideCancel}
            />
          </label>
        </div>
        <div className="pl-4">
          { isSupporter === false && ({
            INITIAL: (
              <>
                <button
                  onClick={prepareCSV}
                  type="button"
                  className="no-underline text-teal text-sm uppercase"
                >
                  Prepare CSV
                </button>

                <button
                  onClick={prepareFinancialCSV}
                  type="button"
                  className="no-underline text-teal text-sm uppercase"
                >
                  Prepare Finance CSV
                </button>
              </>
            ), 
            PREPARING: (
              <img alt="" src={Spinner} className="w-10" />
            ),
            READY: (
              <a
                href={csvBlobUrl}
                download={`Export_${new Date().toISOString().split("T")[0]}.csv`}
                className="no-underline text-teal text-sm uppercase"
              >
                Download&nbsp;
                <span role="img" aria-label="Arrow">
                ↗️
                </span>
              </a>
            ),
            FAILED: (
              <div>Error:-(</div>
            )
          }[csvStatus])}
        </div>
      </div>

      <div className="flex justify-end text-sm">
        Pax:
        {paxCount}- Bookings:
        {bookings.length}
      </div>
    </div>
  )
}

BookingFilters.propTypes = {
  filterProps: PropTypes.shape({
    filteredBookings: PropTypes.objectOf(PropTypes.object),
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    paxCount: PropTypes.number,
    csvBlobUrl: PropTypes.string,
    filters: PropTypes.array,
    setToday: PropTypes.func,
    handleChangeStart: PropTypes.func,
    handleChangeEnd: PropTypes.func,
    toggleFilter: PropTypes.func,
    hideCanceled: PropTypes.func,
    toggleHideCancel: PropTypes.func,
    handleSearchChanged: PropTypes.func,
    isSupporter: PropTypes.bool
  }).isRequired,
  prepareCSV: PropTypes.func.isRequired
}

export default BookingFilters
