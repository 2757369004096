/* eslint-disable react/prop-types */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Auth, API } from "aws-amplify"

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      showValidationError: false,
      isAdmin: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.userHasAuthenticated = props.userHasAuthenticated.bind(this)
    this.setLoggedInUser = props.setLoggedInUser.bind(this)
  }

  // Get user admin status
  getAdminStatusRequest = user => {
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", `/users/${user.username}`, {
      headers: { Authorization: jwtToken }
    })
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { props, state } = this

    // We're loading now, show spinner
    this.setState({ isLoading: true })

    try {
      const user = await Auth.signIn(state.email, state.password)

      // check if admin
      const userAttributes = await this.getAdminStatusRequest(user)
      this.setState({ isAdmin: userAttributes.isAdmin })

      if (userAttributes.isAdmin === true) {
        this.userHasAuthenticated(true)
        this.setLoggedInUser(user)
        
        // props.history.push("/")

      } else {
        this.setState({ isAdmin: false })
        this.userHasAuthenticated(false)
        this.setState({ isLoading: false })
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e.message)
      this.setState({ isLoading: false })
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  render() {
    const {
      email,
      password,
      showValidationError,
      isLoading,
      isAdmin
    } = this.state
    return (
      <>
        <div className="container mx-auto flex flex-col items-center justify-center h-screen">
          <div className="bg-white w-full md:w-1/3 shadow">
            <div className="bg-teal p-4 text-teal-darker text-lg uppercase font-bold">
              <div className="text-xs font-bold text-teal-darkest">RE:BOOK</div>
              <div className="leading-none">CopenHill</div>
            </div>
            <form onSubmit={this.handleSubmit} className="p-4">
              <div className="mt-4">
                <label htmlFor="email" className="text-sky text-sm font-bold">
                  E-mail
                  <div>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      value={email}
                      onChange={this.handleChange}
                      className="text-sm appearance-none border-2 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
                    />
                  </div>
                </label>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="password"
                  className="text-sky text-sm font-bold"
                >
                  Password
                  <div>
                    <input
                      id="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      type="password"
                      className="text-sm appearance-none border-2 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
                    />
                  </div>
                </label>
              </div>

              {/* <div className="mt-4">
                  <button
                    onClick={showForgotPassword}
                    className="underline text-grey-dark text-sm"
                    type="button"
                  >
                    Forgot your password?
                  </button>
                </div> */}

              {showValidationError && (
                <div
                  className="text-sm mt-4 bg-red-lightest border-l-2 border-red text-red-dark p-2"
                  role="alert"
                >
                  <p className="font-bold m-0">Wrong username or password</p>
                  <p className="m-0">
                    We can&apos;t find your username - or your password is not
                    correct
                    {/* Vi kan ikke finde dit brugernavn, eller også er dit password forkert. */}
                  </p>
                </div>
              )}

              <div className="mt-4 flex flex-row justify-end">
                {isLoading ? (
                  <div className="text-grey">Logging in...</div>
                ) : (
                  <button
                    type="submit"
                    className="shadow bg-teal hover:bg-teal-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  >
                    Log in
                  </button>
                )}
              </div>
            </form>
          </div>

          {/* Admin validation errer  */}
          {isAdmin === false && (
            <div
              className="bg-red-lightest border border-red-light text-red-dark px-4 py-3 rounded relative mt-6 w-full md:w-1/3 shadow"
              role="alert"
            >
              <span className="block sm:inline">
                User doesn&apos;t have Admin privileges!
              </span>
            </div>
          )}
        </div>
      </>
    )
  }
}

Login.propTypes = {
  userHasAuthenticated: PropTypes.func.isRequired,
  setLoggedInUser: PropTypes.func.isRequired
}

export default Login
