import React from 'react'
import PropTypes from 'prop-types'

// class CustomerTableRow extends React.Component {
const CustomerTableRow = ({
  voucher,
  handleCancelVoucher
}) => {

  const { id, code, currency, amount, used, createdAt, metaData, user, voucherTypeId, cancelledAt, bookingOrders, description } = voucher
  const createdAtDate = new Date(createdAt)
  const latestBookingOrder = bookingOrders && bookingOrders.slice(-1).pop()
  const VoucherUsedAtDate = latestBookingOrder && latestBookingOrder.VoucherUsedAt && new Date(latestBookingOrder.VoucherUsedAt)
  const AmountToBeDisplayed = `${(parseInt(amount, 10)/100).toLocaleString(navigator.language, { minimumFractionDigits: 2 })} 
                                ${ currency !== null ? currency.toUpperCase() : ''}`

  return (
    <tr 
      style={{borderBottom: '1px solid #eee'}}
      className={`${voucher.cancelledAt && 'bg-grey-lightest text-grey'} text-sm hover:bg-teal-lightest border-l-4 hover:border-teal border-white bg-white shadow`}
    >
      <td className="p-2 pl-2">
        {id}
      </td>
      <td className="p-2 pr-4 whitespace-no-wrap">
        {code}
      </td>
      
      <td className="p-2 whitespace-no-wrap">
        {/* A little nested ternary operators */}
        {(voucherTypeId === 1 || voucherTypeId === null) 
          ? (
            <>{amount ? AmountToBeDisplayed : ''}</>
          ) : (
            <>{amount ? `${amount}%` : ''}</>
          )}
      </td>

      <td className="p-2 pr-4 whitespace-no-wrap text-teal">
        {user && (
          <a
            href={`mailto:${user.email}`}
            className={`${voucher.cancelledAt ? 'text-teal-light' : 'text-teal'} no-underline`}
          >
            {user.email}
          </a>
        )}
        
      </td>
      <td className="p-2 pr-4 whitespace-no-wrap">
        {description}
      </td>
      <td className="text-sm p2 whitespace-no-wrap">
        {voucher.validFrom && voucher.validTo && (
          <>
            {new Date(voucher.validFrom).toLocaleDateString("da-DK", { day: '2-digit', month: '2-digit', year: 'numeric' })}
            &nbsp;-&nbsp;
            {new Date(voucher.validTo).toLocaleDateString("da-DK", { day: '2-digit', month: '2-digit', year: 'numeric' })}
          </>
        )}
      </td>
      <td className="p-2 text-right">
        {voucher.maximumRedemptions || 1}
      </td>

      <td className="p-2 pl-2">
        { createdAt !== null ? createdAtDate.toLocaleDateString("da-DK", { day: '2-digit', month: '2-digit', year: 'numeric' }) : ''}
      </td>
      <td className="p-2 pl-2">
        { VoucherUsedAtDate ? VoucherUsedAtDate.toLocaleDateString("da-DK", { day: '2-digit', month: '2-digit', year: 'numeric' }) : ''}
      </td>
      
      <td className="p-2 w-full whitespace-no-wrap">
        {
          // Not cancelled & not used voucher -> show "Cancel" button
          !cancelledAt && !used ? (
            <button
              onClick={e => handleCancelVoucher(id, true, e)}
              className="no-underline text-red hover:text-teal-darker"
              type="button"
            >
              WITHDRAW&nbsp;
            </button>
          ) : (
            <>
              {!used ? (
                // Cancelled voucher, show "Uncancel" button
                <button
                  onClick={e => handleCancelVoucher(id, false, e)}
                  className="no-underline text-teal hover:text-teal-darker"
                  type="button"
                >
                  RESTORE&nbsp;
                </button>
              ) : ''}
            </>
          )
        }
      </td>
      
      {/* <td className="p-2">
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline text-teal uppercase"
        >
          Open&nbsp;
          <span role="img" aria-label="Arrow">↗️</span>
        </a>
      </td> */}
      
      <td className="p-2">
        {metaData && metaData.orderId && (
          <a
            href={`https://dashboard.stripe.com/orders/${metaData.orderId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline text-teal uppercase"
          >
          
          Stripe&nbsp;
            <span role="img" aria-label="Arrow">↗️</span>
          </a>
        )}
      </td>
    </tr> 
  )
}

CustomerTableRow.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    currency: PropTypes.string,
    amount: PropTypes.number,
    used: PropTypes.bool,
    createdAt: PropTypes.instanceOf(Date),
    metaData: PropTypes.shape({
      orderId: PropTypes.number
    }),
    user: PropTypes.shape({
      email: PropTypes.string
    }),
    voucherTypeId: PropTypes.number,
    cancelledAt: PropTypes.instanceOf(Date),
    bookingOrders: PropTypes.arrayOf(PropTypes.object),
    maximumRedemptions: PropTypes.number,
    validFrom: PropTypes.instanceOf(Date),
    validTo: PropTypes.instanceOf(Date)
  }).isRequired,
  handleCancelVoucher: PropTypes.func.isRequired
}

export default CustomerTableRow