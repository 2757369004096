import React from 'react'

class VoucherAddResults extends React.Component {

  constructor(props) {
    super(props)
       
  }

   
  render() {
      const { result } = this.props
 
    
    return (
      <>
        <h2 className="text-teal-dark">{result.vouchers.length > 0 ? 'Operation successful' : 'Operation not successful!'}</h2>
        <div className="md:flex md:items-center mb-6 mt-6">
            {`Created vouchers: ${result.vouchers.length}`}
        </div>
        { result.nonExistEmails.length > 0 && (
            <div className="flex flex-col mb-6 mt-6">
            <div>
                <span className="text-red-dark font-bold">{`NOTE: `}</span>
                Creation failed for these users
            </div>
            <p>
                {result.nonExistEmails.map(email => (<p>{`- ${email}`}</p>))}
            </p>
            
        </div>
        ) }
        
        <div className="md:flex md:items-center mb-6 mt-6">
        <button type="button" onClick={this.props.closeModal} className="bg-transparent hover:bg-teal text-teal-dark font-semibold hover:text-white py-2 px-4 border border-teal hover:border-transparent rounded-full mr-2 focus:outline-none w-1/4">
            Ok
        </button>
        </div>
      </>
    )
  }

}


export default VoucherAddResults