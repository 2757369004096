import React, { Component } from "react";
import { Auth, API } from 'aws-amplify'

import InstructionFilters from '../components/InstructionFilters'
import InstructionTableRow from '../components/InstructionTableRow'

import Spinner from '../spin.svg'

class Instructions extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      isLoading: true,
      user: null,
      instructions: [],
      isFetching: false,
      autoReload: true
    }

    this.endpoint = ''
    this.keyCount = 0
  }

  componentDidMount() { 
    this.checkLogin() 

    // Refresh and get data every 10 seconds
    this.state.autoReload && setInterval(() => this.checkLogin(), 30000)
  } 

  checkLogin= async () => { 
    try { 
      const [user, endpoint] = await Promise.all([ 
        Auth.currentAuthenticatedUser(), 
        API.endpoint('bookings') 
      ]) 
      this.endpoint = endpoint 
      this.setState({ isLoading: false, user }) 
      this.state.autoReload && this.setToday() 
    } catch (error) { 
      this.setState({ isLoading: false, user: null }) 
    } 
  } 

  fetchBookings = async (startTime, endTime) => {
    const { filters } = this.state
    this.setState({ isLoading: true })
    const { jwtToken } = this.props.user.signInUserSession.idToken

    const instructions = await 
      API.get('admin', `/instructions?date-start=${startTime}&date-end=${endTime}&filters=instruction`, { headers: { Authorization: jwtToken } })

      //console.log(rentals)

    this.setState({ instructions, isLoading: false })
   
  }
  
  setToday = () => {
    // Enable auto reload
    this.setState({ autoReload: true }) 

    const startTime = new Date()
    startTime.setHours(0, 0, 0, 0);
    const endTime = new Date(startTime)
    endTime.setHours(23, 59, 59, 999);

    this.setState({ startTime, endTime })
    this.fetchBookings(startTime, endTime)
  }

  handleChangeStart = (startTime) => {
    const { endTime } = this.state

    // if new start time != today, disable auto reload
    const today = new Date()
    if(today.setHours(0,0,0,0) !== startTime.setHours(0,0,0,0) || today.setHours(0,0,0,0) !== endTime.setHours(0,0,0,0)) { 
      this.setState({ autoReload: false }) 
    } else {
      this.setState({ autoReload: true }) 
    }

    this.setState({startTime})
    this.fetchBookings(startTime, endTime)
  }

  handleChangeEnd = (endTime) => {
    const { startTime } = this.state

    // if new start time != today, disable auto reload
    const today = new Date()
    const tempStart = startTime
    const tempEnd = endTime
    if(today.setHours(0,0,0,0) !== tempStart.setHours(0,0,0,0) || today.setHours(23, 59, 59, 999) !== tempEnd.setHours(23, 59, 59, 999)) { 
      this.setState({ autoReload: false }) 
    } else {
      this.setState({ autoReload: true }) 
    }

    //console.log(endTime)
    this.setState({ endTime })
    this.fetchBookings(startTime, endTime)
  }

  getKey = () => {
    this.keyCount += 1
    return this.keyCount
  }
  
  render() {
    const { instructions, startTime, endTime, isLoading } = this.state

    // if hide canceled checkbox is selected, filter bookings to exclude canceled bookings
    //const filteredBookings = hideCanceled ? bookings.filter(booking => !booking.cancelledAt) : bookings

    const filterProps = { startTime, endTime,  
          setToday: this.setToday,
          handleChangeStart: this.handleChangeStart, 
          handleChangeEnd: this.handleChangeEnd, 
          getKey: this.getKey,
          instructions
    }

     return (
      <div className="container mx-auto">

        {/* instruction filters block */}
        <InstructionFilters filterProps={filterProps} />

        {/* Spinner  */}
        {isLoading ? 
         // Spinner component
         (<div className="mt-4 flex justify-center">
          <img alt="" src={Spinner} className="w-10" />
         </div>)
         : 
         (
          <div className="bg-white shadow">
          <table className="w-full">
            <tbody>

              {/* instruction table header */}
                <tr className="bg-grey">
                  <td className="p-2 pl-2 font-bold">
                    Role
                  </td>
                  <td className="p-2 pl-2 font-bold">
                     Date
                  </td>
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Time
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Name
                  </td>
                
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Email
                  </td>
                
                  <td className="p-2 pl-2 font-bold">
                    Hours
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Instructions
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Type
                  </td>
                 
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Age
                  </td>
                 
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Experience
                  </td>
                 
                </tr>

                {/* Instructions table body */}
              { instructions && instructions.map(instruction => (
                <InstructionTableRow
                  instruction={instruction}
                  key={this.getKey()}
                  />
              ))}
            </tbody>
          </table>
        </div>
         )
        }
      </div>
    )

  }


}


export default Instructions