import { isWeekend } from 'date-fns/fp'
import React from 'react'

class OverridesTableRow extends React.Component {
  render() {
    const { id, date, timeFrom, timeTo, isClosed, isWeekend, description } = this.props.override
    const { isSupporter } = this.props
    const weekDays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    const overrideDate = new Date(date)
    const weekDay = weekDays[overrideDate.getDay()]
    
    return (
      // <div className="container mx-auto">
      <tr 
        style={{borderBottom: '1px solid #eee'}}
        className={`${isClosed && 'bg-grey-lightest text-grey'} hover:bg-teal-lightest border-l-4 hover:border-teal border-white`}
      >
        <td className="p-2 pl-2 text-sm">
          {weekDay}
        </td>
        <td className="p-2 pl-2 text-sm whitespace-no-wrap">
          {overrideDate.toLocaleDateString("da-DK", { day: '2-digit', month: 'long', year: 'numeric' })}
        </td>
        

        <td className="p-2 w-full text-sm whitespace-no-wrap">
          { !isClosed ? (timeFrom && timeTo) ? `Open from: ${timeFrom}:00   Until: ${timeTo}:00` : 'Normal working hours' : 'Closed'}
        </td>

        <td className="p-2 h-6 w-6 text-sm whitespace-no-wrap">
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg> */}
        {isWeekend ? '✓' : ""}
        
        </td>
        
        <td className="p-2 w-full text-sm whitespace-no-wrap">
          {description}
        </td>

        <td className="p-2 w-full text-sm whitespace-no-wrap">
          
            { !isSupporter && (<button
              onClick={e => this.props.handleDeleteOverride(id, e)}
              className="no-underline text-red hover:text-teal-darker"
              type="button"
            >
          DELETE&nbsp;
          
            </button>)}
        
        </td>
       
     
      </tr>     
      
    )
  }
}

export default OverridesTableRow