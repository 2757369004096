import React from 'react'
import PropTypes from 'prop-types'

import DatePicker from 'react-datepicker'

class OverrideAddForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      overrideDate: new Date(),
      timeFrom: '',
      timeTo: '',
      overrideIsClosed: false,
      overrideIsWeekend: false,
      overrideDescription: '',
      errors: {}
    }
    this.keyCount = 0
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { props } = this
    props.clearAddOverrideErrors()
  }
  
  componentWillReceiveProps(props) {
    this.setState({ errors: props.errors, isLoading: props.isLoading  })
  }
  
  getKey = () => {
    this.keyCount += 1
    return this.keyCount
  }

  
  handleDateChange = (date) => {
    this.setState({
      overrideDate: date
    })
  }
  
  handleIsClosedChange = (e) => {
    this.setState({
      overrideIsClosed: e.target.checked
    })
  }
  
  handleIsWeekendChange = (e) => {
    this.setState({
      overrideIsWeekend: e.target.checked
    })
  }
  
  populateOptions = () => {
    
    // construct hours values, just an empty array of 24 items
    const optionsArray = [...Array(24)]
    
    // loop through array to construct hours in format of: 10:00
    const options = optionsArray.map((option, i) => {
      if(i < 10){
        return `0${i}:00`
      } 
      return `${i}:00`
      
    })
    return (
      <>
        {options.map(option => <option key={this.getKey()} value={option}>{option}</option>)}
      </>
    )
  }
  
  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  
  render() {
    const { props } = this
    const { overrideDate, timeFrom, timeTo, overrideIsClosed, overrideIsWeekend, overrideDescription, errors, isLoading } = this.state
    const data = {
        date: overrideDate.setHours(0,0,0,0),
        timeFrom: parseInt(timeFrom, 10),
        timeTo: parseInt(timeTo, 10),
        isClosed: overrideIsClosed,
        isWeekend: overrideIsWeekend,
        description: overrideDescription
    }
    
    return (
      <>
        <span className="text-grey mb-4 text-xl text-teal">
                Add day with special opening hours
        </span>
        <form className="w-full font-sans" onSubmit={(e) => props.handleSubmitNewOverride(data, e)}>
          <div className="text-sm text-grey mt-4">            
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <div className="flex flex-row items-center">
              <div>
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  Choose date:&nbsp;
                </label>
              </div>
              <div>
                <DatePicker
                  selected={overrideDate}
                  dateFormat="d MMM yyyy"
                  onChange={this.handleDateChange}
                  className="text-sm appearance-none border-2 rounded w-32 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
                />
              </div>
            </div>
            
            <p className="text-red text-xs italic mt-2">
              {errors.invalidOverrideDate}
            </p>
            <p className="text-red text-xs italic mt-2">
              {errors.overrideExistError ? 'This date cannot be used. It already has special opening hours.' : null}
            </p>
          </div>

          <div className="mt-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="overrideDescription">
              Description  
              <div>
                <input
                  onChange={this.handleChange}
                  className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal"
                  name="overrideDescription"
                  id="overrideDescription"
                  type="text"
                  placeholder="Type a description" 
                />
              </div>
            </label>
          </div>

          <div className="text-sm text-grey mt-4">
            <div>
              <p>
                <input type="checkbox" id="isWeekend" disabled={overrideIsClosed} onClick={this.handleIsWeekendChange} />
                <span className="text-grey-darker text-sm font-bold ml-2 italic">Weekend rate</span>
              </p>
            </div>
          </div>
          
          <div className="text-sm text-grey mt-4">
            <div className="flex flex-col">
              <div>
                <label htmlFor="isClosed" className="block text-grey-darker text-sm font-bold mb-2">
                  Choose opening hours 
                  
                  {/* <span className="text-grey-dark font-light text-xs italic">assign to specific user email</span> */}
                </label>
              </div>
              <div className="flex flex-row">
                <div>
                  <p>
                    <input type="checkbox" id="isClosed" disabled={overrideIsWeekend} onClick={this.handleIsClosedChange} />
                    <span className="text-grey-darker text-sm font-bold ml-2 italic">Closed</span>
                  </p>
                </div>
              </div>
            </div>

            { !overrideIsClosed && (
            <div className="flex justify-start mt-4">
              <div className="text-grey-darker text-center px-4 py-2 m-2">Open from</div>
              <div className="relative">
                <select onChange={this.handleChange} value={timeFrom} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey" id="timeFrom">
                  {this.populateOptions()}
                </select>
                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>

              <div className="text-grey-darker text-center px-4 py-2 m-2">Untill</div>
              <div className="relative">
                <select onChange={this.handleChange} value={timeTo} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey" id="timeTo">
                  {this.populateOptions()}
                </select>
                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>
            </div>
            )}

            <p className="text-red text-xs italic mt-2">
              {errors.invalidOverrideHours}
            </p>
            
            {/* <input onChange={this.handleChange} className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" name="voucherUserEmail" id="voucherUserEmail" type="text" placeholder="i.e. tom@jones.com" />
            <p className="block text-grey-darker text-sm font-bold mt-2">
              NOTE: 
              <span className="text-grey-dark font-light text-xs italic"> the user has to be already exist</span>
            </p>
            <p className="text-red text-xs italic mt-2">
              {errors.email}
            </p> */}
          </div>

          
          <div className="md:flex md:items-center mb-6 mt-6">
            <button type="submit" className="bg-transparent hover:bg-teal text-teal-dark font-semibold hover:text-white py-2 px-4 border border-teal hover:border-transparent rounded-full mr-2 focus:outline-none w-1/4">
              <>
                {isLoading === true
                  ? "Loading..."
                  : "Add day"}
              </>
            </button>
            <button type="button" onClick={props.closeModal} className="bg-transparent hover:bg-red text-red-dark font-semibold hover:text-white py-2 px-4 border border-red hover:border-transparent rounded-full mr-2 focus:outline-none">
              Cancel
            </button>
          </div>
        </form>
      </>
    )
  }

}

OverrideAddForm.propTypes = {
  clearAddOverrideErrors: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmitNewOverride: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    errors: PropTypes.object
  }),
  isLoading: PropTypes.bool
}

OverrideAddForm.defaultProps = {
  errors: {},
  isLoading: true
}

export default OverrideAddForm