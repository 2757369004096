/* eslint-disable no-nested-ternary */
import React from "react"
import PropTypes from "prop-types"

const AdminsTableRow = props => {
  const { admin, handleAdminUser } = props
  const { id, sub, firstName, lastName, email, isAdmin, isRental, isInstruction, isSupporter } = admin
  const roleName = (isRental === false && isInstruction === false && isSupporter === false && isAdmin === true) ? 'Admin': 
                    isRental === true ? 'Rental' : isInstruction === true ? 'Instructor' : isSupporter === true ? 'Supporter' : 'Member'

  return (
    // <div className="container mx-auto">
    <tr
      style={{ borderBottom: "1px solid #eee" }}
      className=" hover:bg-teal-lightest border-l-4 hover:border-teal border-white"
    >
      <td className="p-2 pl-2 text-sm">{id}</td>
      <td className="p-2 pr-4 whitespace-no-wrap text-sm">
        <a href={`mailto:${email}`} className="no-underline">
          {email}
        </a>
      </td>
      <td className="p-2 w-full text-sm whitespace-no-wrap">
        {`${firstName} ${lastName}`}
      </td>
      <td className="p-2 w-full text-sm whitespace-no-wrap">
        {roleName || ""}
      </td>

      <td className="p-2 w-full text-sm whitespace-no-wrap">
        {isAdmin ? (
          <button
            onClick={() => handleAdminUser(sub, false)}
            className="no-underline text-red hover:text-teal-darker"
            type="button"
          >
            DEMOTE&nbsp;
          </button>
        ) : (
          <>
            <button
              onClick={() => handleAdminUser(sub, true)}
              className="no-underline text-teal hover:text-teal-darker"
              type="button"
            >
              ADMIN&nbsp;
            </button>
            <span className="mr-1">&bull;</span>
            <button
              onClick={() => handleAdminUser(sub, true, "isRental")}
              className="no-underline text-teal hover:text-teal-darker"
              type="button"
            >
              Rental&nbsp;
            </button>
            <span className="mr-1">&bull;</span>
            <button
              onClick={() => handleAdminUser(sub, true, "isInstruction")}
              className="no-underline text-teal hover:text-teal-darker"
              type="button"
            >
              Instruction&nbsp;
            </button>
            <span className="mr-1">&bull;</span>
            <button
              onClick={() => handleAdminUser(sub, true, "isSupporter")}
              className="no-underline text-teal hover:text-teal-darker"
              type="button"
            >
              Supporter&nbsp;
            </button>
          </>
        )}
      </td>

      {/* <td className="p-2">
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline text-teal text-sm uppercase"
          >
            Open&nbsp;
            <span role="img" aria-label="Arrow">↗️</span>
          </a>
        </td> */}
    </tr>
  )
}

AdminsTableRow.propTypes = {
  admin: PropTypes.shape({
    id: PropTypes.number,
    sub: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    isAdmin: PropTypes.bool,
    isRental: PropTypes.bool,
    isInstruction: PropTypes.bool,
    isSupporter: PropTypes.bool
  }).isRequired,
  handleAdminUser: PropTypes.func.isRequired
}

export default AdminsTableRow
