/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import { Auth, API } from 'aws-amplify'

import Routes from './Routes'

import TopMenu from './components/topMenu'
import Login from './containers/Login'
// import { AST_With } from 'terser'
class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      // isLoading: true,
      user: null,
      isAuthenticated: false,
      isAuthenticating: false,
      isRentalUser: false,
      isInstructionUser: false,
      isSupporterUser: false
    }
    this.handleLogout = this.handleLogout.bind(this)
    this.userHasAuthenticated = this.userHasAuthenticated.bind(this)
    this.setLoggedInUser = this.setLoggedInUser.bind(this)
  }



  async componentDidMount() {
    this.setState({ isAuthenticating: true })
    try {
      await Auth.currentSession();

      const user = await Auth.currentAuthenticatedUser()
      this.setState({ user })

      // check if admin 
      const userAttributes = await this.getAdminStatusRequest(user)

      // Set 'isRentalUser' & 'inInstruction' status
      this.setState({ isRentalUser: userAttributes.isRental, isInstructionUser: userAttributes.isInstruction, isSupporterUser: userAttributes.isSupporter })

      if (userAttributes.isAdmin === true) {
        this.userHasAuthenticated(true)
      } else {
        this.userHasAuthenticated(false)
      }
      this.setState({ isAuthenticating: false })
    }
    catch (e) {
      if (e !== 'No current user') {
        // eslint-disable-next-line no-alert
        alert(e);
      }
      this.setState({ isAuthenticating: false })
    }

    // this.setState({ isAuthenticating: false });
  }

  setLoggedInUser(user) {
    this.setState({ user })
  }


  // Get user admin status
  getAdminStatusRequest = user => {
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users/${user.username}`, { headers: { Authorization: jwtToken } })
  }

  userHasAuthenticated(authenticated) {
    this.setState({ isAuthenticated: authenticated });
  }

  async handleLogout() {
    await Auth.signOut()

    this.userHasAuthenticated(false)
    this.setState({ user: null })
    window.location = '/'
  }

  render() {
    // const {isLoading, user, bookings, currentModal, startTime, endTime, paxCount, csvBlobUrl, filters} = this.state
    const { state } = this

    const childProps = {
      isAuthenticated: state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: state.user,
      setLoggedInUser: this.setLoggedInUser,
      isRentalUser: state.isRentalUser,
      isInstructionUser: state.isInstructionUser,
      isSupporterUser: state.isSupporterUser
    }

    return (
      <>

        {/* Top Menu */}
        {state.isAuthenticated && <TopMenu isRentalUser={state.isRentalUser} isInstructionUser={state.isInstructionUser} handleLogout={this.handleLogout} />}

        {/* Routes */}
        { state.isAuthenticating ? null :
          state.isAuthenticated ? 
            <Routes childProps={childProps} /> :
            <Login userHasAuthenticated={this.userHasAuthenticated} setLoggedInUser={this.setLoggedInUser} />
        }

      </>
    )
  }
}

export default App
