import React from "react"
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'


const ReservationsFilters = (props) => {
    const { userAttributes, openAddReservationModal, filterProps } = props
    const { isRental, isSupporter } = userAttributes

    const { startTime, endTime, handleChangeStart, handleChangeEnd, setToday, firstLoad } = filterProps
  
    return (
      <div className="container mx-auto">
        <div className="mt-4 flex items-center">
          <h1 className="mt-4 mr-4 text-teal-darker leading-none">
            Reservations
          </h1>
          <button
            onClick={setToday}
            className="mr-4 mt-4 text-teal-dark"
            type="button"
          >
            Upcoming
          </button>
          <div className="mr-4 mt-4">&bull;</div>
          <div className="text-xs mr-1 mt-4">From</div>
          <div>
            <DatePicker
              selected={startTime}
              selectsStart
              startDate={startTime}
              endDate={endTime}
              dateFormat="d MMM yyyy"
              onChange={handleChangeStart}
              locale="en-GB"
              className="text-sm mt-4 appearance-none border-2 rounded w-32 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
            />
          </div>
          <div className="text-xs mr-1 mt-4">To</div>
          <div>
            <DatePicker
              selected={!firstLoad ? endTime : null}
              selectsEnd
              startDate={startTime}
              endDate={endTime}
              dateFormat="d MMM yyyy"
              onChange={handleChangeEnd}
              locale="en-GB"
              className="text-sm mt-4 appearance-none border-2 rounded w-32 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
            />
          </div>
        </div>

        {isRental === false && isSupporter === false && (
          <div className="flex justify-end items-center">
            <button
              type="button"
              onClick={e => openAddReservationModal(false, 0, e)}
              className="bg-grey hover:bg-grey-dark text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <img
                className="fill-current w-4 h-4 mr-2"
                src="https://img.icons8.com/android/24/000000/plus.png"
                alt="add"
              />
              <span>Add Reservation</span>
            </button>
          </div>
        )}
      </div>
    )
  
}

ReservationsFilters.propTypes = {
  userAttributes: PropTypes.objectOf(PropTypes.object).isRequired,
  openAddReservationModal: PropTypes.func.isRequired,
  filterProps: PropTypes.objectOf(PropTypes.object).isRequired
}

export default ReservationsFilters