import React from 'react'
import PropTypes from 'prop-types'

const ReservationTableRow = (props) => {
  
    const { reservation, openAddReservationModal, handleDeleteReservation, userAttributes } = props
    const dateStart = new Date(reservation.dateStart)
    const dateEnd = new Date(reservation.dateEnd)
  
    
    return (
      // <div className="container mx-auto">
      <tr
        style={{ borderBottom: '1px solid #eee' }}
        className={`${reservation.cancelledAt &&
          'bg-grey-lightest text-grey'} hover:bg-teal-lightest border-l-4 hover:border-teal border-white`}
      >
        <td className="p-2 pl-2 text-sm">
          {dateStart.toLocaleDateString('da-DK', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </td>
        <td className="p-2 whitespace-no-wrap text-sm">
          {dateStart.toLocaleString('en-GB', {
            hour: 'numeric',
            minute: 'numeric'
          })}
          -
          {dateEnd.toLocaleString('en-GB', {
            hour: 'numeric',
            minute: 'numeric'
          })}
        </td>

        <td className="p-2 text-sm whitespace-no-wrap">
          {reservation.amountPeople}
        </td>

        <td className="p-2 pr-4 w-full whitespace-no-wrap text-sm text-teal">
          {reservation.description ? reservation.description : ''}
        </td>

        <td className="p-2 w-full text-sm whitespace-no-wrap">
          {!userAttributes.isRental && !userAttributes.isSupporter && (
            <>
              <button
                onClick={e =>
                  openAddReservationModal(true, reservation.uuid, e)}
                className="no-underline hover:text-teal-darker mr-4"
                type="button"
              >
                EDIT&nbsp;
              </button>
              <button
                onClick={e => handleDeleteReservation(reservation.id, e)}
                className="no-underline text-red hover:text-teal-darker"
                type="button"
              >
                DELETE&nbsp;
              </button>
            </>
          )}
        </td>
      </tr>
    )
  }

ReservationTableRow.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.object).isRequired,
  openAddReservationModal: PropTypes.func.isRequired,
  handleDeleteReservation: PropTypes.func.isRequired,
  userAttributes: PropTypes.objectOf(PropTypes.object).isRequired
}

export default ReservationTableRow