/* eslint-disable no-alert */
import React, { Component } from "react"
import { API, Auth } from "aws-amplify"
import PropTypes from "prop-types"

import Modal from "../components/modal"
import WorkingHoursTableRow from "../components/WorkingHoursTableRow"
import OverridesTableRow from "../components/OverridesTableRow"
import OverrideAddForm from "../components/OverrideAddForm"

import Spinner from "../spin.svg"

class OpenHours extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      settings: {
        workingHours: null
      },
      overrides: [],
      showAddOverrideModal: null,
      addOverrideErrors: {}
    }
  }

  async componentDidMount() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return
    }

    // load open hours and overrides data
    this.loadOpenHoursData()

    await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser()
  
    const userAttributes = await this.getAdminStatusRequest(user)
    this.setState({ isSupporter: userAttributes.isSupporter })
  }

  // Get user admin status
  getAdminStatusRequest = (user) => {
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users/${user.username}`, { headers: { Authorization: jwtToken } })
  }

  // Fetch settings
  getSettings(name) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", `/settings?name=${name}`, {
      headers: { Authorization: jwtToken }
    })
  }

  // Fetch overrides
  getOverrides() {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", `/overrideHours`, {
      headers: { Authorization: jwtToken }
    })
  }

  loadOpenHoursData = async () => {
    // try fetch settings
    this.setState({ isLoading: true })

    try {
      const [data, overrides] = await Promise.all([
        this.getSettings("workingHours"),
        this.getOverrides()
      ])
      // action after fecth
      this.setState({
        settings: {
          workingHours: data ? data.settingValue.days : []
        },
        overrides
      })
    } catch (e) {
      alert(e)
    }
    this.setState({ isLoading: false })
  }

  populateWorkingHoursLists = () => {
    const { settings, isSupporter } = this.state

    if (settings.workingHours) {
      return (
        <>
          <WorkingHoursTableRow
            dayId="0"
            day="Monday"
            workingHoursValue={settings.workingHours[0]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
          <WorkingHoursTableRow
            dayId="1"
            day="Tuesday"
            workingHoursValue={settings.workingHours[1]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
          <WorkingHoursTableRow
            dayId="2"
            day="Wednesday"
            workingHoursValue={settings.workingHours[2]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
          <WorkingHoursTableRow
            dayId="3"
            day="Thursday"
            workingHoursValue={settings.workingHours[3]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
          <WorkingHoursTableRow
            dayId="4"
            day="Friday"
            workingHoursValue={settings.workingHours[4]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
          <WorkingHoursTableRow
            dayId="5"
            day="Saturday"
            workingHoursValue={settings.workingHours[5]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
          <WorkingHoursTableRow
            dayId="6"
            day="Sunday"
            workingHoursValue={settings.workingHours[6]}
            onChange={this.handleTimeChange}
            isSupporter={isSupporter}
          />
        </>
      )
    }

    return null
  }

  handleTimeChange = (dayId, flag, e) => {
    const { settings } = this.state

    if (flag === "from") {
      if (
        Number(e.target.value.substr(0, 2)) >=
        Number(settings.workingHours[dayId].to.substr(0, 2))
      ) {
        alert("Value Should be less than closing time")
        // this.setState({ validateFlag: true})
        return
      }
      const copy = [...settings.workingHours]
      copy[dayId].from = e.target.value

      this.setState({
        settings: {
          workingHours: copy
        }
      })
    } else {
      const copy = [...settings.workingHours]
      copy[dayId].to = e.target.value

      this.setState({
        settings: {
          workingHours: copy
        }
      })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleUpdateWorkingHours = async () => {
    const { settings } = this.state
    const confirmed = window.confirm(
      "Are you sure you want to update working hours?"
    )

    if (!confirmed) {
      return
    }

    this.setState({ isLoading: true })

    try {
      // Use updateSettings to update working hours
      await this.updateSettings({
        name: "workingHours",
        params: {
          settingValue: { days: settings.workingHours }
        }
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    //
    this.setState({ isLoading: false })
  }

  // Add new override hours
  handleSubmitNewOverride = async (data, e) => {
    e.preventDefault()
    this.setState({ isLoading: true })

    const { date, timeFrom, timeTo, isClosed, isWeekend } = data
    // Validations:

    // check if selected date is less than today (not in the future)
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (date < today) {
      this.setState({
        isLoading: false,
        addOverrideErrors: {
          invalidOverrideDate: "Override date should be in the future!"
        }
      })
      return
    }

    // check selected times
    if (!isClosed && !isWeekend && (!timeFrom || !timeTo)) {
      this.setState({
        isLoading: false,
        addOverrideErrors: {
          invalidOverrideHours: "Please provide valid hours"
        }
      })
      return
    }

    // Add new override

    try {
      console.log("QQ override data before post: ", data)
      await this.addNewOverride(data)

      // close Modal
      this.closeModal()

      // Refresh data on page
      this.loadOpenHoursData()
    } catch (err) {
      this.setState({ addOverrideErrors: err.response.data.errors })
    }

    //
    this.setState({ isLoading: false })
  }

  // eslint-disable-next-line no-unused-vars
  handleDeleteOverride = async (id, e) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this override?"
    )

    if (!confirmed) {
      return
    }

    this.setState({ isLoading: true })

    try {
      await this.deleteOverride(id)

      // refresh overrides list
      this.loadOpenHoursData()
    } catch (err) {
      alert(err)
    }

    //
    this.setState({ isLoading: false })
  }

  deleteOverride = id => {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.del("admin", "/overrideHours", {
      body: { id },
      headers: { Authorization: jwtToken }
    })
  }

  openAddOverrideModal = e => {
    e.preventDefault()

    this.setState({
      showAddOverrideModal: true
    })
  }

  closeModal = () => {
    this.setState({ showAddOverrideModal: null })
  }

  clearAddOverrideErrors = () => {
    this.setState({ addOverrideErrors: {} })
  }

  // Add new Override API request
  addNewOverride(data) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.post("admin", "/overrideHours", {
      body: data,
      headers: { Authorization: jwtToken }
    })
  }

  // Update settings (can be used to set any setting value)
  updateSettings(params) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.put("admin", `/settings`, {
      body: params,
      headers: { Authorization: jwtToken }
    })
  }

  render() {
    const {
      isLoading,
      overrides,
      showAddOverrideModal,
      addOverrideErrors,
      isSupporter
    } = this.state

    return (
      <>
        {/* Add new override modal */}
        {showAddOverrideModal && (
          <Modal closeAction={this.closeModal}>
            <OverrideAddForm
              handleSubmitNewOverride={this.handleSubmitNewOverride}
              closeModal={this.closeModal}
              errors={addOverrideErrors}
              clearAddOverrideErrors={this.clearAddOverrideErrors}
              isLoading={isLoading}
            />
          </Modal>
        )}

        <div className="container mx-auto">
          <div className="mt-4 flex items-center">
            <h2 className="mr-4 text-teal-darker leading-none">
              Default opening Hours
            </h2>
          </div>
        </div>

        <div className="container mx-auto mt-4">
          {isLoading && (
            <div className="mt-4 flex justify-center">
              <img src={Spinner} alt="spinner" />
            </div>
          )}

          <div className="bg-white shadow flex justify-start">
            <div className="mt-4 flex justify-start">
              <table className="w-full">
                <tbody>
                  {this.populateWorkingHoursLists()}
                  <tr>
                    <td>
                      <div className="flex flex-row-reverse">
                        { !isSupporter && 
                        (<button
                          type="button"
                          onClick={this.handleUpdateWorkingHours}
                          className="bg-grey hover:bg-grey-dark text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center"
                        >
                          <span>Save</span>
                        </button>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="container mx-auto flex flex-row justify-between mt-8">
          <div>
            <h2 className="mr-4 text-teal-darker leading-none">
              Special opening hours
            </h2>
          </div>

          <div>
            { !isSupporter && (<button
              type="button"
              onClick={this.openAddOverrideModal}
              className="bg-grey hover:bg-grey-dark text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <img
                className="fill-current w-4 h-4 mr-2"
                src="https://img.icons8.com/android/24/000000/plus.png"
                alt="add new"
              />
              <span>Add Day</span>
            </button>)}
          </div>
        </div>

        <div className="container mx-auto mt-2 mb-6">
          {/* Override hours section start */}
          <div className="bg-white shadow flex justify-start">
            <table className="w-full">
              <tbody>
                {/* overrides table header */}
                <tr className="bg-grey">
                  <td className="p-2 pl-2 font-bold">Day</td>
                  <td className="p-2 pl-2 font-bold">Date</td>
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Opening hours
                  </td>
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Weekend rate
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Description
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Action
                  </td>
                </tr>

                {/* overrides table body */}
                {overrides &&
                  overrides.map(override => (
                    <OverridesTableRow
                      override={override}
                      handleDeleteOverride={this.handleDeleteOverride}
                      isSupporter={isSupporter}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

OpenHours.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default OpenHours
