import React, { Component } from "react";
import PropTypes from 'prop-types'
import { Auth, API } from 'aws-amplify'

import Modal from '../components/modal'
import BookingEdit from './bookingEdit'
import BookingFilters from '../components/BookingFilters'
import BookingTableRow from '../components/bookingTableRow'

import Spinner from "../spin.svg"

const [ LOADING, LOADED, FAILED ] = [ 'LOADING', 'LOADED', 'FAILED' ]
class Bookings extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      status: LOADING,
      bookings: [],
      paxCount: 0,
      filters: [],
      hideCanceled: true,
      search: ''
    }

    this.endpoint = ''
    this.keyCount = 0
  }

  async componentDidMount() { 
    try {
      await this.setIsSupporter()
      this.setToday()
    } catch (e) {
      this.setState({ status: FAILED, errorMsg: e.message })
    }
  } 

  setIsSupporter = async () => {
    try {
      const { isSupporter } = await this.getUserAttributes() || {}
      this.setState({ isSupporter })
      return
    } catch (e) {
      throw Error(e)
    }
  }
  
  getUserAttributes = () => {
    // const user = await Auth.currentAuthenticatedUser()
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users/${user.username}`, { headers: { Authorization: jwtToken } })
  }

  fetchBookings = async (startTime, endTime) => {
    const { filters } = this.state
    try {
      this.setState({ status: LOADING })
      const user = await Auth.currentAuthenticatedUser()
      const { jwtToken } = user.signInUserSession.idToken
      const bookings = await API.get('bookings', `/bookings?date-start=${startTime}&date-end=${endTime}&filters=${filters}`, { headers: { Authorization: jwtToken } })
      this.setState({ bookings, status: LOADED })
      this.updatePaxCount() 
    } catch(e) {
      this.setState({ status: FAILED, errorMsg: e.message})
    }
  }

  fetchSearchBookings = async (searchTerm) => {
    const { filters } = this.state
    const { user } = this.props
    if (searchTerm !== '') {
      this.setState({ status: LOADING })
      const { jwtToken } =user.signInUserSession.idToken
  
      const searchBookings = await API.get('bookings', `/bookings/${searchTerm}?filters=${filters}`, {
        headers: { Authorization: jwtToken } 
      })
      
      this.setState({ bookings: searchBookings , status: LOADED })
      
      // update paxCount
      this.updatePaxCount() 
    } else {
      this.setToday()
    }
  }
  
  updatePaxCount = () => {
    const { bookings, hideCanceled } = this.state

    // if hide canceled checkbox is selected, filter bookings to exclude canceled bookings
    const filteredBookings = hideCanceled ? bookings.filter(booking => !booking.cancelledAt) : bookings
    const paxCount = filteredBookings.reduce((a, b) => a + b.bookingOrders.length, 0)
    this.setState({
      paxCount
    })
  }
  

  setToday = () => {
    const startTime = new Date()
    startTime.setHours(0, 0, 0, 0);
    const endTime = new Date(startTime)
    endTime.setHours(23, 59, 59, 999);

    this.setState({ startTime, endTime })
    this.fetchBookings(startTime, endTime)
  }

  handleChangeStart = (startTime) => {
    const { endTime } = this.state
    this.setState({startTime})
    this.fetchBookings(startTime, endTime)
  }

  handleChangeEnd = (endTime) => {
    const { startTime } = this.state
    this.setState({ endTime })
    this.fetchBookings(startTime, endTime)
  }

  handleSearchChanged = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value
      }
      ,
      () => this.fetchSearchBookings(this.state.search)
    )
    
  }

  getKey = () => {
    this.keyCount += 1
    return this.keyCount
  }

  closeModal= () =>  {
    this.setState({ currentModal: null })
  }

  updateBooking = (booking) => {
    const { bookings } = this.state
    const updateIndex = bookings.findIndex(b => b.id === booking.id)
    bookings[updateIndex] = booking
    this.setState({bookings})
    this.closeModal()
  }

  openBookingModal = (booking) => {
    this.setState({
      currentModal: (
        <Modal closeAction={this.closeModal}>
          <BookingEdit
            booking={booking}
            afterSubmit={this.updateBooking}
            updatePax={this.updatePaxCount}
          />
        </Modal>
      )
    })
  }
  
  toggleFilter = (e) => {
    const { filters, startTime, endTime } = this.state
    const index = filters.indexOf(e.target.name);
    // eslint-disable-next-line no-unused-expressions
    index === -1
      ? filters.push(e.target.name)
      : filters.splice(index, 1)
    this.setState({ filters })
    this.fetchBookings(startTime, endTime)
  }

  toggleHideCancel = () => {
    // First toggle hideCanceled, and in callback (after value really changed, updatePaxCount )
    const { hideCanceled } = this.state
    this.setState({
      hideCanceled: !hideCanceled
    }, () => this.updatePaxCount())
  }
    
  render() {

    const { bookings, currentModal, startTime, endTime, paxCount, filters, hideCanceled, status, errorMsg } = this.state
    const { isSupporter } = this.state

    // if hide canceled checkbox is selected, filter bookings to exclude canceled bookings
    const filteredBookings = hideCanceled ? bookings.filter(booking => !booking.cancelledAt) : bookings

    const filterProps = { filteredBookings, startTime, endTime, paxCount, filters, 
          setToday: this.setToday,
          handleChangeStart: this.handleChangeStart, 
          handleChangeEnd: this.handleChangeEnd, 
          toggleFilter: this.toggleFilter,
          getKey: this.getKey, 
          openBookingModal: this.openBookingModal,
          hideCanceled,
          toggleHideCancel: this.toggleHideCancel,
          handleSearchChanged: this.handleSearchChanged,
          isSupporter
    }

    return (
      <div className="container mx-auto">
        {currentModal && currentModal}

        {/* Booking filters block */}
        <BookingFilters filterProps={filterProps} />
        
        {/* Bookings block */}
        {status === LOADING && (
          // Spinner component
          <div className="mt-4 flex justify-center">
            <img alt="" src={Spinner} className="w-10" />
          </div>
        )} 
        
        {status === FAILED && (
          <div className="p-4 mt-6 border border-red bg-white">
            <h1>Error</h1>
            <div>{errorMsg}</div>
          </div>
        )}

        {status === LOADED && (
          <div className="bg-white shadow">
            <table className="w-full">
              <tbody>
                {/* Bookings table header */}
                <tr className="bg-grey">
                  <td className="p-2 pl-2 font-bold">Date</td>
                  <td className="p-2 pl-2 font-bold">Time</td>
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Booker e-mail
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Booker name
                  </td>

                  <td className="p-2 pl-2 font-bold">Total price</td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Total people
                  </td>

                  <td className="p-2 pl-2 font-bold" />
                </tr>

                {/* Bookings table body */}
                {filteredBookings.map(booking => (
                  <BookingTableRow
                    booking={booking}
                    key={this.getKey()}
                    openBookingModal={this.openBookingModal}
                    isSupporter={isSupporter}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )

  }


}

Bookings.propTypes = {
  // isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired
}


export default Bookings