import React from 'react'
import PropTypes from 'prop-types'
import { Auth, API } from 'aws-amplify'
import BookingEditForm from '../components/bookingEditForm'


class BookingEdit extends React.Component {

  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
  }

  async submit(newValues) {
    const { booking, updatePax, afterSubmit } = this.props
    const user = await Auth.currentAuthenticatedUser()
    const { jwtToken } = user.signInUserSession.idToken

    await API.put("bookings", `/bookings/${booking.id}`, {
      headers: {
        Authorization: jwtToken
      },
      body: newValues
    })

    // update paxCount
    updatePax()

    afterSubmit(newValues)
  }

  render() {
    const { booking } = this.props
    return (
      <BookingEditForm
        booking={booking}
        submit={this.submit}
      />
    )
  }
}

BookingEdit.propTypes = {
  booking: PropTypes.objectOf(PropTypes.object).isRequired,
  updatePax: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired
}

export default BookingEdit