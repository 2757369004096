import React from "react"
import PropTypes from "prop-types"

const AdminsFilters = ({ handleChange, searchTerm })=> (
  <div className="container mx-auto">
    <div className="mt-4 flex items-center">
      <h2 className="mr-4 text-teal-darker leading-none">
        Add Admin
      </h2>
  
      <div className="mr-4">&bull;</div>
      <div className="text-teal-dark mr-1">
        Find a user to make admin
      </div>
    
      <form className="w-full max-w-sm">
        <div className="flex items-center border-b border-b-2 border-teal-500 py-2">
          <input
            onChange={handleChange}
            name="searchTerm"
            id="searchTerm"
            type="text"
            value={searchTerm}
            placeholder="Name or email"
            className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
          />

        </div>
      </form>

      
      <div className="flex flex-grow items-center text-xs ml-4 leading-none">
        {/* <strong>Filters:</strong>
        <label className="mx-2 whitespace-no-wrap">
          Filter1&nbsp;&nbsp;
      <input name="rental" type="checkbox" />
        </label>
        <label className="mx-2 whitespace-no-wrap">
          Filter2&nbsp;&nbsp;
      <input name="instruction" type="checkbox" />
        </label> */}
      </div>
      
    </div>

  
  </div>
)

AdminsFilters.propTypes = {
  handleChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string
}

AdminsFilters.defaultProps = {
  searchTerm: ''
}

export default AdminsFilters