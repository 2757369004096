import React from "react"
import PropTypes from "prop-types"

const CustomerFilters = props => {
  const { handleChange, csvBlobUrl } = props

  // const { bookings, startTime, endTime, paxCount, csvBlobUrl, filters, setToday,
  //   handleChangeStart, handleChangeEnd, toggleFilter } = this.props.filterProps

  return (
    <div className="container mx-auto">
      <div className="mt-4 flex items-center">
        <h1 className="mr-4 text-teal-darker leading-none">Customers</h1>

        <div className="mr-4">&bull;</div>
        <div className="text-teal-dark mr-1">Search by:</div>

        <form className="w-full max-w-sm" onSubmit={e => { e.preventDefault(); }}>
          <div className="flex items-center border-b border-b-2 border-teal-500 py-2">
            <input
              onChange={handleChange}
              className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              name="searchTerm"
              id="searchTerm"
              type="text"
              placeholder="Customer name or email"
            />
          </div>
        </form>

        <div className="flex flex-grow items-center text-xs ml-4 leading-none">
          {/* <strong>Filters:</strong>
            <label className="mx-2 whitespace-no-wrap">
              Filter1&nbsp;&nbsp;
          <input name="rental" type="checkbox" />
            </label>
            <label className="mx-2 whitespace-no-wrap">
              Filter2&nbsp;&nbsp;
          <input name="instruction" type="checkbox" />
            </label> */}
        </div>
        <div className="flex justify-end items-center text-xs ml-4 leading-none">
          {csvBlobUrl && (
            <a
              href={csvBlobUrl}
              download={`Export_${new Date().toISOString().split("T")[0]}.csv`}
              className="no-underline text-teal text-sm uppercase"
            >
              CSV&nbsp;
              <span role="img" aria-label="Arrow">
                ↗️
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

CustomerFilters.propTypes = {
  handleChange: PropTypes.func.isRequired,
  csvBlobUrl: PropTypes.string.isRequired
}

export default CustomerFilters
