import React from 'react'
import PropTypes from 'prop-types'

const VoucherFilters = ({ handleChange, csvBlobUrl, hideCanceled, toggleHideCancel, openAddVoucherModal }) => (
  <div className="container mx-auto">
    <div className="mt-4 flex items-center">
      <h1 className="mr-4 text-teal-darker leading-none">
        Vouchers
      </h1>
      <div className="mr-4">&bull;</div>
      <div className="text-teal-dark mr-1">
        Search by:
      </div>
      <form className="w-full max-w-sm" onSubmit={e => { e.preventDefault(); }}>
        <div className="flex items-center border-b border-b-2 border-teal-500 py-2">
          <input
            onChange={handleChange}
            name="searchTerm"
            id="searchTerm"
            type="text"
            placeholder="Voucher code or buyer e-mail"
            className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
          />
          <label className="mx-2 whitespace-no-wrap text-xs" htmlFor="hideCanceled">
            Hide canceled&nbsp;&nbsp;
            <input name="hideCanceled" type="checkbox" checked={hideCanceled} onChange={toggleHideCancel} />
          </label>
        </div>
      </form>
      <div className="flex flex-grow ml-4 leading-none" />
      <div className="flex justify-end items-center text-xs ml-4 leading-none">
        <a
          href={csvBlobUrl}
          download={`Export_${new Date().toISOString().split('T')[0]}.csv`}
          className="no-underline text-teal text-sm uppercase"
        >
          CSV (latest 5.000)&nbsp;
          <span role="img" aria-label="Arrow">↗️</span>
        </a>
      </div>
    </div>
    <div className="flex justify-end items-center">
      <button type="button" onClick={openAddVoucherModal} className="bg-grey hover:bg-grey-dark text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center">
        <img alt="icon" className="fill-current w-4 h-4 mr-2" src="https://img.icons8.com/android/24/000000/plus.png" />
        <span>Add Voucher</span>
      </button>
    </div>
  </div>
)

VoucherFilters.propTypes = {
  handleChange: PropTypes.func.isRequired,
  csvBlobUrl: PropTypes.string,
  hideCanceled: PropTypes.bool.isRequired,
  toggleHideCancel: PropTypes.func.isRequired,
  openAddVoucherModal: PropTypes.func.isRequired
}

VoucherFilters.defaultProps = {
  csvBlobUrl: null
}

export default VoucherFilters