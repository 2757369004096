import React, { Component } from 'react'
import { API } from 'aws-amplify'
import PropTypes from 'prop-types'
import CustomerFilters from '../components/CustomerFilters'
import CustomerTableRow from '../components/CustomerTableRow'

import Spinner from '../spin.svg'

class Customers extends Component {
  constructor(props) {
    super(props)
    this.timeout =  0;

    this.state = {
      isLoading: false,
      customers: [],
      searchTerm: '',
      csvBlobUrl: null

    }

  }

  async componentDidMount() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return
    }

    this.setState({ isLoading: true });

    this.fetchCustomers(true)
    this.setState({ isLoading: false })
  }

  // Fetch customers depending on search term or initial limit (currently set to 100 in back-end if not limit provided)
  getCustomers(limit) {
    const { user } = this.props
    const { searchTerm } = this.state
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users?searchTerm=${searchTerm || ""}${limit ? `&limit=${limit}` : ""}`, { headers: { Authorization: jwtToken } })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, () => this.fetchCustomers())

  }

  fetchCustomers = (initial) => {
    if(this.timeout) clearTimeout(this.timeout)
    this.setState({ isLoading: true })

    this.timeout = setTimeout( async () => {
      // Fetch customers initially with limit of 100 customers (if no search term is provided)
      try {
        const customers = await this.getCustomers(100)
        this.setState({ customers, isLoading: false })
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(e)
      }

      // Handle CSV
      this.handleCsvBlob()

      // If page is initially visited, load with not waiting, otherwise wait for typing to finish
    }, initial ? 0 : 2000)
  }

  handleCsvBlob = () => {
    const { customers, searchTerm } = this.state
    // prepare data set to be insreted into CSV (apply filters to dataset)
    const filteredCustomers = customers ? customers.filter(customer => {
      const fname = customer.firstName === null ? '' : customer.firstName
      return fname.toLowerCase().includes(searchTerm.toLowerCase())
        || customer.email.toLowerCase().includes(searchTerm.toLowerCase())
    }) : []


    // Add data headers
    const headerData = [0, "First Name", "Last Name", "E-mail"]

    // process data to be in array format
    const filteredCustomersProcessed = filteredCustomers.map(customer => ([customer.id, customer.firstName, customer.lastName, customer.email]))
    const filteredCustomersWithHeader = [headerData, ...filteredCustomersProcessed]

    const csvContent = `data:text/csv;charset=utf-8,${
      filteredCustomersWithHeader.map(e => e.join(",")).join("\n")}`

    const csvBlobUrl = encodeURI(csvContent)

    this.setState({ csvBlobUrl })


  }

  populateCustomerList() {
    const { customers } = this.state
    return customers.map(customer => (
      <CustomerTableRow
        key={customer.id}
        customer={customer}
      />
    ))
  }

  render() {
    const { customers, isLoading, csvBlobUrl } = this.state

    return (

      <div className="container mx-auto">
        <CustomerFilters handleChange={this.handleChange} csvBlobUrl={csvBlobUrl} />

        {isLoading ?
          <div className="mt-4 flex justify-center"><img alt="" src={Spinner} className="w-10" /></div>
          : (
            <div className="bg-white shadow">
              <div className="mt-4 flex items-center">
                <table className="w-full">
                  <tbody>
                    {/* Customers table header */}
                    <tr className="bg-grey">
                      <td className="p-2 pl-2 font-bold">
                        ID
                      </td>
                      <td className="p-2 pl-2 font-bold">
                        E-mail
                      </td>
                      <td className="p-2 pl-2 font-bold">
                        Name
                      </td>

                      <td className="p-2 pl-2 font-bold">
                        Membership
                      </td>

                      <td className="p-2 pl-2 font-bold">
                        Member since
                      </td>

                      <td className="p-2 pl-2 font-bold" />
                    </tr>

                    {/* Customers table body */}
                    {customers ? this.populateCustomerList() : null}
                  </tbody>
                </table>
              </div>
            </div>
        )}
      </div>
      // <div>customers</div>

    )
  }
}

Customers.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default Customers