import React, { Component } from "react"
import { Auth, API } from "aws-amplify"
import PropTypes from "prop-types"

import RentalFilters from "../components/RentalFilters"
import RentalTableRow from "../components/RentalTableRow"

import Spinner from "../spin.svg"

class Rentals extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      rentals: {},
      autoReload: true
    }

    this.endpoint = ""
    this.keyCount = 0
  }

  componentDidMount() {
    const { autoReload } = this.state
    this.checkLogin()

    // Refresh and get data every 10 seconds
    // autoReload && setInterval(() => this.checkLogin(), 30000)
    if (autoReload) {
      this.autoReloadHandle = setInterval(() => this.checkLogin(), 30000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoReloadHandle)
  }

  checkLogin = async () => {
    const { autoReload } = this.state
    try {
      const [endpoint] = await Promise.all([
        Auth.currentAuthenticatedUser(),
        API.endpoint("bookings")
      ])
      this.endpoint = endpoint
      this.setState({ isLoading: false })

      if (autoReload) this.setToday()
    } catch (error) {
      this.setState({ isLoading: false })
    }
  }

  fetchBookings = async (startTime, endTime) => {
    const { user } = this.props
    this.setState({ isLoading: true })
    const { jwtToken } = user.signInUserSession.idToken

    const rentals = await API.get(
      "admin",
      `/rentals?date-start=${startTime}&date-end=${endTime}&filters=rental`,
      { headers: { Authorization: jwtToken } }
    )
    // console.log(rentals)

    this.setState({ rentals, isLoading: false })
    console.log('rentals in state', rentals)
  }

  setToday = () => {
    // Enable auto reload
    this.setState({ autoReload: true })

    const startTime = new Date()
    startTime.setHours(0, 0, 0, 0)
    const endTime = new Date(startTime)
    endTime.setHours(23, 59, 59, 999)

    this.setState({ startTime, endTime })
    this.fetchBookings(startTime, endTime)
  }

  handleChangeStart = startTime => {
    const { endTime } = this.state

    // if new start time != today, disable auto reload
    const today = new Date()
    if (
      today.setHours(0, 0, 0, 0) !== startTime.setHours(0, 0, 0, 0) ||
      today.setHours(0, 0, 0, 0) !== endTime.setHours(0, 0, 0, 0)
    ) {
      this.setState({ autoReload: false })
    } else {
      this.setState({ autoReload: true })
    }

    this.setState({ startTime })
    this.fetchBookings(startTime, endTime)
  }

  handleChangeEnd = endTime => {
    const { startTime } = this.state

    // if new start time != today, disable auto reload
    const today = new Date()
    const tempStart = startTime
    const tempEnd = endTime
    if (
      today.setHours(0, 0, 0, 0) !== tempStart.setHours(0, 0, 0, 0) ||
      today.setHours(23, 59, 59, 999) !== tempEnd.setHours(23, 59, 59, 999)
    ) {
      this.setState({ autoReload: false })
    } else {
      this.setState({ autoReload: true })
    }

    this.setState({ endTime })
    this.fetchBookings(startTime, endTime)
  }

  getKey = () => {
    this.keyCount += 1
    return this.keyCount
  }

  render() {
    const {
      rentals,
      startTime,
      endTime,
      paxCount,
      isLoading,
      filters
    } = this.state

    const filterProps = {
      startTime,
      endTime,
      paxCount,
      filters,
      setToday: this.setToday,
      handleChangeStart: this.handleChangeStart,
      handleChangeEnd: this.handleChangeEnd,
      getKey: this.getKey,
      rentals
    }

    
    return (
      <div className="container mx-auto">
        {/* Rental filters block */}
        <RentalFilters filterProps={filterProps} />

        {/* Spinner  */}
        {isLoading ? (
          // Spinner component
          <div className="mt-4 flex justify-center">
            <img alt="" src={Spinner} className="w-10" />
          </div>
        ) : (
          <div className="bg-white shadow">
            <table className="w-full">
              <tbody>
                {/* Rentals table header */}
                <tr className="bg-grey">
                  <td className="p-2 pl-2 font-bold">Role</td>
                  <td className="p-2 pl-2 font-bold">Date</td>
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Time
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Name
                  </td>

                  <td className="p-2 pl-2 font-bold">Rental</td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Type
                  </td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Head
                  </td>

                  <td className="p-2 pl-2 font-bold">Height</td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Weight
                  </td>
                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Foot
                  </td>

                  <td className="p-2 pl-2 font-bold">Exp</td>

                  <td className="p-2 pl-2 font-bold whitespace-no-wrap">
                    Insurance
                  </td>
                </tr>

                {/* Bookings table body */}
                {rentals.data &&
                  rentals.data.map(rental => (
                    <RentalTableRow rental={rental} key={this.getKey()} />
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

Rentals.propTypes = {
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default Rentals
