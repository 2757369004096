/* eslint-disable react/no-array-index-key */
import React from "react"
import PropTypes from "prop-types"

const CustomerTableRow = props => {
  const { customer } = props
  const { id, firstName, lastName, email, createdAt, memberships } = customer
  const createdAtDate = new Date(createdAt)

  return (
    // <div className="container mx-auto">
    <tr
      style={{ borderBottom: "1px solid #eee" }}
      className="hover:bg-teal-lightest border-l-4 hover:border-teal border-white"
    >
      <td className="p-2 pl-2 text-sm">{id}</td>
      <td className="p-2 pr-4 whitespace-no-wrap text-sm">
        <a href={`mailto:${email}`} className="no-underline">
          {email}
        </a>
      </td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {`${firstName} ${lastName}`}
      </td>

      <td className="p-2 w-full text-sm">
        {memberships &&
          memberships.filter(membership => membership.status === null).map((membership, i) => {
            return (
              <span
                className="inline-flex bg-grey-light rounded h-6 px-3 justify-center items-center mr-2 mb-1"
                key={i}
              >
                {`${membership.type
                  .split(/(?=[A-Z])/)
                  .filter(e => e !== "pass" && e !== "Annual")
                  .join(" ")}`}
              </span>
            )
          })}
      </td>

      <td className="p-2 pl-2 text-sm whitespace-no-wrap">
        {createdAt !== null
          ? createdAtDate.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour12: false,
              hour: "2-digit",
              minute: "2-digit"
            })
          : ""}
      </td>

      <td className="p-2">
        {/* <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline text-teal text-sm uppercase"
          >
            Open&nbsp;
            <span role="img" aria-label="Arrow">↗️</span>
          </a> */}
      </td>
    </tr>
  )
}

CustomerTableRow.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
    memberships: PropTypes.array
  }).isRequired
}

export default CustomerTableRow
