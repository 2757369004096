import React from "react"
import PropTypes from "prop-types"

import DatePicker from "react-datepicker"

const InstructionFilters = props => {
  const { filterProps } = props
  const {
    startTime,
    endTime,
    setToday,
    handleChangeStart,
    handleChangeEnd,
    instructions
  } = filterProps

  return (
    <div className="container mx-auto mb-4">
      <div className="mt-4 flex items-center">
        <h1 className="mr-4 text-teal-darker leading-none">Instructions</h1>
        <button
          onClick={setToday}
          className="mr-4 text-teal-dark"
          type="button"
        >
          Today
        </button>
        <div className="mr-4">&bull;</div>
        <div className="text-xs mr-1">From</div>
        <div>
          <DatePicker
            selected={startTime}
            selectsStart
            startDate={startTime}
            endDate={endTime}
            dateFormat="d MMM yyyy"
            onChange={handleChangeStart}
            className="text-sm appearance-none border-2 rounded w-32 p-2 leading-tight focus:outline-none focus:bg-white focus:border-teal"
          />
        </div>
        <div className="text-xs mx-1">To</div>
        <div>
          <DatePicker
            selected={endTime}
            selectsEnd
            startDate={startTime}
            endDate={endTime}
            dateFormat="d MMM yyyy"
            onChange={handleChangeEnd}
            className="text-sm appearance-none border-2 rounded w-32 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
          />
        </div>
      </div>

      <div className="flex justify-end text-sm">
        Pax:
        {instructions.length}
      </div>
    </div>
  )
}

InstructionFilters.propTypes = {
  filterProps: PropTypes.shape({
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    setToday: PropTypes.func,
    handleChangeStart: PropTypes.func,
    handleChangeEnd: PropTypes.func,
    instructions: PropTypes.array
  }).isRequired
}

export default InstructionFilters
