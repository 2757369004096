import React from "react"
import PropTypes from "prop-types"

const InstructionTableRow = props => {
  const { instruction } = props
  const dateStart = new Date(instruction.dateStart)
  const dateEnd = new Date(instruction.dateEnd)

  return (
    <tr
      style={{ borderBottom: "1px solid #eee" }}
      className={`${instruction.cancelledAt &&
        "bg-grey-lightest text-grey"} hover:bg-teal-lightest border-l-4 hover:border-teal border-white cursor-pointer`}
    >
      <td className="p-2 text-sm">{instruction.role}</td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {dateStart.toLocaleDateString("da-DK", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })}
      </td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {dateStart.toLocaleString("en-GB", {
          hour: "numeric",
          minute: "numeric"
        })}
        -
        {dateEnd.toLocaleString("en-GB", {
          hour: "numeric",
          minute: "numeric"
        })}
      </td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {`${instruction.firstName} ${instruction.lastName}`}
      </td>
      <td className="p-2 text-sm">{instruction.email || ""}</td>
      <td className="p-2 text-sm">{instruction.hours}</td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {instruction.instruction}
      </td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {instruction.skiOrBoard}
      </td>
      <td className="p-2 text-sm whitespace-no-wrap">{instruction.age}</td>
      <td className="p-2 text-sm whitespace-no-wrap">
        {instruction.experience}
      </td>
    </tr>
  )
}

InstructionTableRow.propTypes = {
  instruction: PropTypes.objectOf(PropTypes.object).isRequired
}

export default InstructionTableRow
