/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { Auth, API } from 'aws-amplify'
import PropTypes from 'prop-types'


class Home extends Component {
  async componentDidMount() {
    
    try {
      await Auth.currentSession();
                        
      const user = await Auth.currentAuthenticatedUser()
      // this.setState({ user })

      // check if admin 
      const userAttributes = await this.getAdminStatusRequest(user)

      const { isRental, isInstruction } = userAttributes
      const { history } = this.props

      if (!isRental && !isInstruction) {
        // Not a rental or instruction admin, go to normal homepage
        history.push("/bookings")
      } else if (isRental) {
        // If rental or instruction admin, go to corresponding homepage
        history.push("/rentals")
      } else {
        history.push("/instructions")
      }
         
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    
    // this.setState({ isAuthenticating: false})
  }
  
  // Get user admin status
  getAdminStatusRequest = (user) => {
    const { jwtToken } = user.signInUserSession.idToken
    return API.get('admin', `/users/${user.username}`, { headers: { Authorization: jwtToken } })
  }
  
  render() {
    const { isAuthenticated } = this.props
    return (
      <div>
        {isAuthenticated ? (<div className="container mx-auto">Dashobard Container</div>) : (<div>Not Authenticated</div>)}
      </div>
    )
  }
}

Home.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

export default Home
