import React from 'react'

class RentalTableRow extends React.Component {
  render() {
    const { rental } = this.props
    const dateStart = new Date(rental.dateStart)
    const dateEnd = new Date(rental.dateEnd)

    return (
      <tr 
        style={{borderBottom: '1px solid #eee'}}
        className={`hover:bg-teal-lightest hover:border-teal border-white cursor-pointer`}
      >
        <td className="p-2 text-sm">
        {rental.role}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {dateStart.toLocaleDateString("da-DK", { day: '2-digit', month: '2-digit', year: 'numeric' })}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {dateStart.toLocaleString("en-GB", { hour: 'numeric', minute: 'numeric' })}
          -
          {dateEnd.toLocaleString("en-GB", { hour: 'numeric', minute: 'numeric' })}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {`${rental.firstName} ${rental.lastName}`}
        </td>
        <td className="p-2 text-sm">
        {rental.rental}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.skisOrBoard}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.head}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.height}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.weight}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.footSize}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.experience}
        </td>
        <td className="p-2 text-sm whitespace-no-wrap">
        {rental.hasInsurance && 'Yes'}
        </td>

     
      </tr>      
    )
  }
}

export default RentalTableRow