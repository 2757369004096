import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from "react-router-dom"

import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'

import Home from './containers/Home'
import Login from './containers/Login'
import Bookings from './containers/Bookings'
import Reservations from './containers/Reservations'
import Customers from './containers/Customers'
import Vouchers from './containers/Vouchers'
import Admins from './containers/Admins'
import OpenHours from './containers/OpenHours'
import Settings from './containers/Settings'
import Rentals from './containers/Rentals'
import Instructions from './containers/Instructions'
import KeyCards from './containers/KeyCards'


import NotFound from './containers/NotFound'

const Switcher = ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/bookings" exact component={Bookings} props={childProps} />
    <AuthenticatedRoute path="/reservations" exact component={Reservations} props={childProps} />
    <AuthenticatedRoute path="/customers" exact component={Customers} props={childProps} />
    <AuthenticatedRoute path="/vouchers" exact component={Vouchers} props={childProps} />
    <AuthenticatedRoute path="/admins" exact component={Admins} props={childProps} />
    <AuthenticatedRoute path="/openhours" exact component={OpenHours} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/rentals" exact component={Rentals} props={childProps} />
    <AuthenticatedRoute path="/instructions" exact component={Instructions} props={childProps} />
    <AuthenticatedRoute path="/keycards" exact component={KeyCards} props={childProps} />
    
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>
)

Switcher.propTypes = {
  childProps: PropTypes.shape({
    childProps: PropTypes.object
  }).isRequired
}

export default Switcher