import React from 'react'

export default function WorkingHoursTableRow(props) {
    const { from, to } = props.workingHoursValue
    const { dayId, isSupporter } = props
     
    return (
      <tr>
        <td>
          <div className="flex justify-start">
            <div className="text-grey-darker text-center px-4 py-2 m-2 w-32"><h3>{props.day}</h3></div>
            <div className="text-grey-darker text-center px-4 py-2 m-2">Open from:</div>
            <div className="relative">
              <select disabled={isSupporter} onChange={(e) => props.onChange(dayId, "from", e)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey" id="grid-state">
                {populateOptions(from)}
              </select>
              <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
            <div className="text-grey-darker text-center px-4 py-2 m-2">until:</div>
            <div className="relative">
              <select disabled={isSupporter} onChange={(e) => props.onChange(dayId, "to", e)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey" id="grid-state">
                {populateOptions(to)}
              </select>
              <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
}

const populateOptions = (value) => {

    // construct hours values, just an empty array of 24 items
    const optionsArray = [...Array(24)]

    // loop through array to construct hours in format of: 10:00
    const options = optionsArray.map((option, i) => {
      if(i < 10){
        return `0${i}:00`
      } 
        return `${i}:00`
      
    })
    return (
      <>
        {options.map((option, i) => {
            const selected = (value === option)
            return (<option key={i} selected={selected} value={option}>{option}</option>)
        }
        )}
      </>
    )
  }
