import React, { Component } from "react"
import { API } from "aws-amplify"
import PropTypes from "prop-types"
import VoucherFilters from "../components/VoucherFilters"
import VoucherTableRow from "../components/VoucherTableRow"
import Modal from "../components/modal"
import VoucherAddForm from "../components/VoucherAddForm"
import VoucherAddResults from "../components/VoucherAddResults"

import Spinner from "../spin.svg"

class Vouchers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      vouchers: [],
      skus: [],
      searchTerm: "",
      csvBlobUrl: null,
      showAddVoucherModal: false,
      showAddResultsModal: false,
      addVoucherErrors: {},
      addVouchersResult: {},
      hideCanceled: true
    }
    this.handleSubmitNewVoucher = this.handleSubmitNewVoucher.bind(this)
    this.resetSkusSelection = this.resetSkusSelection.bind(this)
    this.openAddVoucherModal = this.openAddVoucherModal.bind(this)
    this.clearAddVoucherErrors = this.clearAddVoucherErrors.bind(this)
    this.handleProductSelect = this.handleProductSelect.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return
    }

    // load vouchers
    this.loadVouchers()

    // load Skus
    await this.loadSkus()

    // process CSV export
    this.handleCsvBlob()
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchTerm } = this.state
    if(prevState.searchTerm !== searchTerm) {
      // load vouchers
      this.loadVouchers()
    }
  }

  // Fetch all vouchers
  getVouchers(limit) {
    const { user } = this.props
    const { searchTerm } = this.state
    const { jwtToken } = user.signInUserSession.idToken
    return API.get("admin", `/vouchers?searchTerm=${searchTerm || ""}${limit ? `&limit=${limit}` : ''}`, {
      headers: { Authorization: jwtToken }
    })
  }

  // Fetch all skus
  getSkus() {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.post("admin", "/stripe/proxy", {
      body: { endpoint: "productsWithSkus.list", parameters: { limit: 100 } },
      headers: { Authorization: jwtToken }
    })
  }

  validateEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  toggleHideCancel = () => {
    // First toggle hideCanceled
    const { hideCanceled } = this.state
    this.setState({
      hideCanceled: !hideCanceled
    })
  }

  closeResultsModal = () => {
    this.setState({ showAddResultsModal: null })
  }

  // eslint-disable-next-line no-unused-vars
  handleCancelVoucher = async (id, flag, e) => {
    this.setState({ isLoading: true })

    try {
      await this.updateVoucher(id, { cancelledAt: flag ? new Date() : null })

      // refresh overrides list
      this.loadVouchers()
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err)
    }

    //
    this.setState({ isLoading: false })
  }

  // Used to load retrieved SKUs to state in the form we want
  mapSkusToState = Skus => {
    const skus = Skus.map(sku => {
      return {
        productName: sku.productName,
        productId: sku.productId,
        skus: sku.skus.data
          .filter(skuInner => skuInner.active)
          .map(skuInner => ({
            id: skuInner.id,
            attributes: skuInner.attributes,
            selected: false
          }))
      }
    })

    return skus
  }

  async loadSkus() {
    this.setState({ isLoading: true })

    //
    try {
      const skus = await this.getSkus()

      this.setState({
        skus: this.mapSkusToState(skus)
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    //
    this.setState({ isLoading: false })
  }

  // Add new voucher
  addNewVoucher(data) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.post("admin", "/vouchers", {
      body: data,
      headers: { Authorization: jwtToken }
    })
  }

  async handleSubmitNewVoucher(data, e) {
    const { skus } = this.state
    e.preventDefault()
    this.setState({ isLoading: true })

    // check if voucher quantity is valid
    if (Number.isNaN(data.qty) || data.qty === "") {
      this.setState({
        isLoading: false,
        addVoucherErrors: { invalidVoucherQuantity: "Enter a valid quantity!" }
      })
      return
    }

    // check if voucher type is selected
    if (data.voucherType === null) {
      this.setState({
        isLoading: false,
        addVoucherErrors: {
          invalidVoucherTypeSelection: "You must choose a voucher type!"
        }
      })
      return
    }

    // validate percentage value
    if (data.voucherType === 2 && (data.amount <= 0 || data.amount > 100)) {
      this.setState({
        isLoading: false,
        addVoucherErrors: {
          invalidAmount: "Please enter a percentage value i.e. 1:100"
        }
      })
      return
    }

    // check if voucher amount is empty or not a number
    if (Number.isNaN(data.amount) || data.amount === "") {
      this.setState({
        isLoading: false,
        addVoucherErrors: { invalidAmount: "Amount should be a valid number!" }
      })
      return
    }

    // validate email field
    const emails = data.userEmail.split(",").map(item => item.trim())
    const nonVaildEmails = emails.filter(
      email => !this.validateEmail(email.trim())
    )

    if (data.userEmail !== "" && nonVaildEmails.length > 0) {
      // const nonValidEmailsString =  nonVaildEmails.reduce((acc, email) => (`${acc}, ${email}`))
      this.setState({
        isLoading: false,
        addVoucherErrors: { invalidEmail: "Some email values are invalid!" }
      })
      return
    }

    // prepare selected products sku's in form of array i.e. ["sku_ERpjv14KImWfrh","sku_ERpiCMtyTrXMkT"]
    const validForArray = []
    skus.map(sku =>
      validForArray.push(
        ...sku.skus
          .filter(skuObj => skuObj.selected === true)
          .map(skuObj => ({
            skuId: skuObj.id,
            quantity: skuObj.quantity
          }))
      )
    )

    // check if maximum redemptions is valid    
    if (data && data.voucherMaximumRedemptions && !Number.isInteger(parseInt(data.voucherMaximumRedemptions, 10))) {
      this.setState({
        isLoading: false,
        addVoucherErrors: { invalidMaximumRedemptions: "Maxium redemptions should be a valid number or nothing" }
      })
      return
    }

    // check for valid data interval if restricted
    if (
      (data.voucherValidFrom && !data.voucherValidTo)
      || (!data.voucherValidFrom && data.voucherValidTo)
      || (data.voucherValidFrom && !(data.voucherValidFrom instanceof Date))
      || (data.voucherValidTo && !(data.voucherValidTo instanceof Date))
      || ((data.voucherValidFrom && data.voucherValidFrom.getTime()) > (data.voucherValidTo && data.voucherValidTo.getTime()))
    ) {
      this.setState({
        isLoading: false,
        addVoucherErrors: { invalidDateInterval: "Date interval is not valid" }
      })
      return
    }

    try {
      const addVouchersResult = await this.addNewVoucher({
        amount: parseInt(data.amount, 10),
        emails,
        voucherTypeId: data.voucherType,
        validFor: validForArray,
        qty: data.qty,
        maximumRedemptions: parseInt(data.voucherMaximumRedemptions, 10),
        validFrom: data.voucherValidFrom,
        validTo: data.voucherValidTo,
        description: data.description
      })

      // populate insert result data
      // console.log(addVouchersResult)
      this.setState({ showAddResultsModal: true, addVouchersResult })

      // close Modal
      this.closeModal()

      // refresh vouchers
      this.loadVouchers()
    } catch (err) {
      this.setState({ addVoucherErrors: err.response.data.errors })
    }

    //
    this.setState({ isLoading: false })
  }

  handleChange(e) {
    // setState is async, so, we calling "handleCsvBlob" after state is completely updated.
    this.setState(
      {
        [e.target.id]: e.target.value
      }
      // ,() => this.handleCsvBlob()
    )
  }

  handleProductSelect(id, name, checked, quantity) {
    const { state } = this

    // update skus "selected" state for specific product
    const copy = [...state.skus]
    const idAsIndex = id.split("-")[1]

    // find index of the product to updated related SKUs "selected" status
    const productIndex = copy.findIndex(product => product.productId === name)
    copy[productIndex].skus[idAsIndex].selected = checked
    copy[productIndex].skus[idAsIndex].quantity = quantity

    this.setState({
      skus: copy
    })
  }

  handleCsvBlob() {
    // prepare data set to be insreted into CSV (apply filters to dataset)
    const { csvVouchers } = this.state
    const filteredVouchers = csvVouchers || []

    // Add data headers
    const headerData = [
      0,
      "Voucher code",
      "email",
      "Currency",
      "Amount",
      "createdAt",
      "Is used"
    ]

    // process data to be in array format
    const filteredVouchersProcessed = filteredVouchers.map(voucher => [
      voucher.id,
      voucher.code,
      // voucher.metaData !== null ? voucher.metaData.email : "",
      voucher.user && voucher.user.email ? voucher.user.email : "",
      voucher.currency,
      voucher.amount,
      voucher.createdAt,
      voucher.used
    ])
    const filteredVouchersWithHeader = [
      headerData,
      ...filteredVouchersProcessed
    ]

    const csvContent = `data:text/csv;charset=utf-8,${filteredVouchersWithHeader
      .map(e => e.join(","))
      .join("\n")}`

    const csvBlobUrl = encodeURI(csvContent)

    this.setState({ csvBlobUrl })
  }

  closeModal() {
    this.setState({ showAddVoucherModal: null })
  }

  resetSkusSelection() {
    // used to reset all skus "selected" property to "false", when openeing "Add voucher" form
    const { skus } = this.state

    const copy = skus.map(Sku => {
      return {
        productId: Sku.productId,
        productName: Sku.productName,
        skus: Sku.skus.map(sku => ({
          id: sku.id,
          attributes: sku.attributes,
          selected: false
        }))
      }
    })

    this.setState({
      skus: copy
    })
  }

  openAddVoucherModal(e) {
    e.preventDefault()

    // load Skus - to refresh products selected status (all "false")
    this.resetSkusSelection()

    this.setState({
      showAddVoucherModal: true
    })
  }

  clearAddVoucherErrors() {
    this.setState({ addVoucherErrors: {} })
  }

  populateVoucherList() {
    const { vouchers, hideCanceled } = this.state
    // const { vouchers, searchTerm, hideCanceled } = this.state
    // const searchTermLowered = searchTerm.toLowerCase()

    // if hide canceled checkbox is selected, filter vouchers to exclude canceled vouchers
    const filteredVouchers = hideCanceled
      ? vouchers.filter(voucher => !voucher.cancelledAt)
      : vouchers

    // if there is a searchTerm (flag = true), get all matching vouchers, don't limit results to 100
    // if (flag) {
    //   return filteredVouchers
    //     .filter(voucher => {
    //       const code = voucher.code === null ? "" : voucher.code
    //       return code.toLowerCase().includes(searchTermLowered) 
    //         || (
    //           voucher.metaData
    //           && voucher.metaData.email
    //           && voucher.metaData.email.includes(searchTermLowered)
    //         )
    //     })
    //     .map(voucher => (
    //       <VoucherTableRow
    //         key={voucher.id}
    //         voucher={voucher}
    //         handleCancelVoucher={this.handleCancelVoucher}
    //       />
    //     ))
    // }
    // if there is no searchTerm (flag = flase), get vouchers, limit listings to 100
    return filteredVouchers
      .map(voucher => (
        <VoucherTableRow
          key={voucher.id}
          voucher={voucher}
          handleCancelVoucher={this.handleCancelVoucher}
        />
      ))
  }

  updateVoucher(id, params) {
    const { user } = this.props
    const { jwtToken } = user.signInUserSession.idToken
    return API.put("admin", `/vouchers/${id}`, {
      body: params,
      headers: { Authorization: jwtToken }
    })
  }

  async loadVouchers() {
    this.setState({ isLoading: true })

    try {
      const vouchers = await this.getVouchers()
      if (vouchers && vouchers.errorMessage)
        throw vouchers
      this.setState({ vouchers })

      // This way because the approx max amount is 5000
      const csvVouchers = await this.getVouchers(5000)
      this.setState({ csvVouchers }, () => this.handleCsvBlob())
    } catch (e) {
      alert(e)
      // eslint-disable-next-line no-console
      console.error(e)
    }

    //
    this.setState({ isLoading: false })
  }

  render() {
    const {
      vouchers,
      isLoading,
      showAddVoucherModal,
      searchTerm,
      hideCanceled,
      showAddResultsModal,
      addVouchersResult,
      addVoucherErrors,
      skus,
      csvBlobUrl
    } = this.state
    const searchTermLowered = searchTerm.toLowerCase()

    return (
      <div className="container mx-auto">
        {/* Show voucher results Modal, only when there are non-exist emails */}
        {showAddResultsModal &&
          addVouchersResult.nonExistEmails &&
          addVouchersResult.nonExistEmails.length > 0 && (
            <Modal closeAction={this.closeResultsModal}>
              <VoucherAddResults
                closeModal={this.closeResultsModal}
                result={addVouchersResult}
              />
            </Modal>
          )}

        {/* Add new voucher modal */}
        {showAddVoucherModal && (
          <Modal closeAction={this.closeModal}>
            <VoucherAddForm
              handleSubmitNewVoucher={this.handleSubmitNewVoucher}
              closeModal={this.closeModal}
              errors={addVoucherErrors}
              clearAddVoucherErrors={this.clearAddVoucherErrors}
              isLoading={isLoading}
              skus={skus}
              handleProductSelect={this.handleProductSelect}
            />
          </Modal>
        )}

        {/* Vouchers Filters component */}
        <VoucherFilters
          handleChange={this.handleChange}
          csvBlobUrl={csvBlobUrl}
          openAddVoucherModal={this.openAddVoucherModal}
          hideCanceled={hideCanceled}
          toggleHideCancel={this.toggleHideCancel}
        />

        {isLoading ? (
          // Spinner component
          <div className="mt-4 flex justify-center">
            <img alt="" src={Spinner} className="w-10" />
          </div>
        ) : (
          // Vouchers Table
          <div className="mt-4 flex items-center">
            <table className="w-full">
              <thead>
                {/* Vouchers table header */}
                <tr className="bg-grey">
                  <th className="text-left p-2 pl-2 font-bold">ID</th>
                  <th className="text-left p-2 pl-2 font-bold">Code</th>
                  <th className="text-left p-2 pl-2 font-bold whitespace-no-wrap">
                    Amount
                  </th>
                  <th className="w-full text-left p-2 pl-2 font-bold whitespace-no-wrap">
                    E-mail
                  </th>
                  <th className="w-full text-left p-2 pl-2 font-bold whitespace-no-wrap">
                    Description
                  </th>
                  <th className="text-left whitespace-no-wrap">
                    Valid from/to
                  </th>
                  <th className="text-left whitespace-no-wrap">
                    Max redemptions
                  </th>
                  <th className="text-left p-2 pl-2 font-bold whitespace-no-wrap">
                    Date created
                  </th>
                  <th className="text-left p-2 pl-2 font-bold whitespace-no-wrap">
                    Last Used
                  </th>
                  <th className="text-left p-2 pl-2 font-bold whitespace-no-wrap">
                    Action
                  </th>
                  <th className="text-left p-2 pl-2 font-bold">Extra</th>
                </tr>

                {/* Vouchers table body */}
                {vouchers && searchTermLowered
                  ? this.populateVoucherList(true)
                  : this.populateVoucherList(false)}
              </thead>
            </table>
          </div>
        )}
      </div>
    )
  }
}

Vouchers.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired
}

export default Vouchers
