import React from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'

import DatePicker from 'react-datepicker'
import Spinner from '../spin.svg'

class ReservationAddForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      dateStart: null,
      dateEnd: null,
      amountPeople: null,
      description: '',
      errors: {}
    }

    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount() {
    // if editMode, load reservation data
    if(this.props.editMode) {
      this.loadReservation()
      
    }
  }

  loadReservation = async () => {
    this.setState({ isLoading: true });

    //
    try {
      const reservation = await this.getReservation(this.props.editId)
      console.log(reservation)
      const { dateStart, dateEnd, amountPeople, description } = reservation
      this.setState({
        dateStart: new Date(dateStart), 
        dateEnd: new Date(dateEnd), 
        amountPeople, 
        description
      })
    } catch (e) {
      console.log(e)
    }

    //
    this.setState({ isLoading: false })
  }

   // get reservation by uuid
   getReservation(uuid) {
    const { jwtToken } = this.props.user.signInUserSession.idToken
    return API.get('admin', `/reservations/${uuid}`, { headers: { Authorization: jwtToken } })
  }
  
  componentWillReceiveProps(props) {
    
  }
  
   handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleTimeChange = (value, e) => {
    this.setState({
      [value]: e
    })
  }

     
  render() {
    const { props } = this
    const { dateStart, dateEnd, amountPeople, description, errors, isLoading } = this.state
    const data = {
       dateStart,
       dateEnd,
       amountPeople,
       description,
       dateExpire: dateEnd,
       byAdmin: true
    }
    
    return (
      <>
        <span className="text-grey mb-4 text-xl font-bold text-teal">
            { props.editMode ? 'Edit Reservation' : 'Add Reservation'}
        </span>
        {(isLoading || this.props.isLoading) && (
        // Spinner component
        <div className="mt-4 flex justify-center">
          <img alt="" src={Spinner} className="w-10" />
        </div>) }
        <div className="h-px my-4 bg-teal-lighter" />
        <form className="w-full font-sans" onSubmit={(e) => props.handleSubmitNewReservation(data, props.editMode, props.editId, e)}>
        <div className="text-sm text-grey mt-4">
            
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dateStart">
              Start time&nbsp;
              <DatePicker
                selected={dateStart}
                onChange={(e) => this.handleTimeChange('dateStart', e)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="d MMMM yyyy, HH:mm"
                className="appearance-none border-2 rounded w-64 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
              />
            </label>
            <p className="text-red text-xs italic mt-2">
              {errors.invalidDateStart}
            </p>

            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dateEnd">
              End time &nbsp;
              <DatePicker
                selected={dateEnd}
                onChange={(e) => this.handleTimeChange('dateEnd', e)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="d MMMM yyyy, HH:mm"
                className="appearance-none border-2 rounded w-64 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal"
              />
            </label>
            <p className="text-red text-xs italic mt-2">
              {errors.invaliddateEnd}
            </p>

            
        </div>

        <div className="mt-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="amountPeople">
            People amount  
          </label>
          <input onChange={this.handleChange} 
            className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" 
            name="amountPeople" 
            id="amountPeople" 
            type="text" 
            value={amountPeople ? amountPeople : ''}
            placeholder="Type an amount" />
            
        </div>

        <div className="mt-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="description">
            Description  
          </label>
          <input onChange={this.handleChange} 
            className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:border-teal" 
            name="description" 
            id="description" 
            type="text"
            value={description ? description : ''} 
            placeholder="Type a description" />
            
        </div>


          
          <div className="md:flex md:items-center mb-6 mt-6">

            <button type="submit" className="bg-transparent hover:bg-teal text-teal-dark font-semibold hover:text-white py-2 px-4 border border-teal hover:border-transparent rounded-full mr-2 focus:outline-none w-1/4">
              <>
                {isLoading === true
                  ? "Loading..."
                  : "Save"}
              </>
            </button>
            <button type="button" onClick={props.closeModal} className="bg-transparent hover:bg-red text-red-dark font-semibold hover:text-white py-2 px-4 border border-red hover:border-transparent rounded-full mr-2 focus:outline-none">
              Cancel
            </button>
            
          </div>

        </form>
      </>
    )
  }

}


export default ReservationAddForm