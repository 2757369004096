import React from "react"
import PropTypes from "prop-types"

const BookingTableRow = props => {
  const { booking, openBookingModal, isSupporter } = props
  const dateStart = new Date(booking.dateStart)
  const dateEnd = new Date(booking.dateEnd)

  return (
    <tr
      onClick={() => isSupporter === false ? openBookingModal(booking) : null}
      style={{ borderBottom: "1px solid #eee" }}
      className={`${booking.cancelledAt &&
        "bg-grey-lightest text-grey"} hover:bg-teal-lightest border-l-4 hover:border-teal border-white ${isSupporter === false && 'cursor-pointer'}`}
    >
      <td className="p-2 pl-2 text-sm">
        {dateStart.toLocaleDateString("da-DK", {
          timeZone: 'Europe/Copenhagen',
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })}
      </td>
      <td className="p-2 whitespace-no-wrap text-sm">
        {dateStart.toLocaleString("en-GB", {
          timeZone: 'Europe/Copenhagen',
          hour: "numeric",
          minute: "numeric"
        })}
        -
        {dateEnd.toLocaleString("en-GB", {
          timeZone: 'Europe/Copenhagen',
          hour: "numeric",
          minute: "numeric"
        })}
      </td>
      <td className="p-2 pr-4 whitespace-no-wrap text-sm">
        <a
          href={`mailto:${booking.user && booking.user.email}`}
          className={`${
            booking.cancelledAt ? "text-teal-light" : "text-teal"
          } no-underline`}
        >
          {booking.user && booking.user.email}
        </a>
      </td>
      <td className="p-2 w-full text-sm whitespace-no-wrap">
        {`${booking.user && booking.user.firstName} ${booking.user &&
          booking.user.lastName}`}
      </td>
      <td className="p-2 pr-4 whitespace-no-wrap text-sm text-right">
        {booking.metaData && booking.metaData.localizedAmount}
      </td>
      <td className="p-2 whitespace-no-wrap">
        {booking.bookingOrders.length}
        &nbsp;
        <span className="text-xs">pax</span>
      </td>
      <td className="p-2">
        <a
          href={`https://dashboard.stripe.com/orders/${booking.stripeOrderId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline text-teal text-sm uppercase"
        >
          Stripe&nbsp;
          <span role="img" aria-label="Arrow">
            ↗️
          </span>
        </a>
      </td>
    </tr>
  )
}

BookingTableRow.propTypes = {
  booking: PropTypes.objectOf(PropTypes.object).isRequired,
  openBookingModal: PropTypes.func.isRequired,
  isSupporter: PropTypes.bool.isRequired
}

export default BookingTableRow
